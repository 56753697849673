import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';
import EmployeeActions from './actions/EmployeeActions';
import { history } from '../../../helpers/history';

import MDBFileupload from 'mdb-react-fileupload';

import Image from "../image/Image";

import PasswordWithGenerator from 'react-password-with-generator'


class EmployeeForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      uid: false,
      entity: this.props.entity,
      locationsFilter: this.props.searchComponent.state.locationsFilter,
      logindaysFilter: this.props.searchComponent.state.logindaysFilter,
      rolesFilter: this.props.searchComponent.state.rolesFilter,
      loaded: true,
      username: '',
      password: '',
      mail: '',
      roles: [],
      field_firstname: '',
      field_lastname: '',
      field_birthday: new Date(Date.now()),
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_image: '',
      field_location: [],
      field_login_days: []
    }

    this.locationFilterRef = React.createRef();
    this.logindaysFilterRef = React.createRef();
    this.rolesFilterRef = React.createRef();
    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onGetPickerValue = this._onGetPickerValue.bind(this);
  }

  componentDidMount() {

    let component = this;

    if(this.props.entity) {

      var selectedLocations = new Array();
      Object.entries(this.props.locations).forEach(function(item, key){
        selectedLocations.push(item[1].nid[0].value.toString());
      })
      var locations = this.props.searchComponent.state.locations;
      var locationsFilter = new Array();
      if(locations) {
        Object.entries(locations).forEach(function(item, key){
          var hasLocation = selectedLocations.includes(item[0].toString());
          var optionItem = {
            checked: (hasLocation) ? true : false,
            text: item[1].title[0].value,
            value: item[0]
          }
          locationsFilter.push(optionItem);
          //console.log(item);
        });
      }


      var selectedLoginDays = new Array();
      Object.entries(this.props.logindays).forEach(function(item, key){
        selectedLoginDays.push(item[1].tid[0].value.toString());
      })
      var logindays = this.props.searchComponent.state.logindays;
      var logindaysFilter = new Array();
      if(logindays) {
        Object.entries(logindays).forEach(function(item, key){
          var hasLoginday = selectedLoginDays.includes(item[0].toString());
          var optionItem = {
            checked: (hasLoginday) ? true : false,
            text: item[1].name[0].value,
            value: item[0]
          }
          logindaysFilter.push(optionItem);
          //console.log(item);
        });
      }

      var roles = this.props.searchComponent.state.roles;
      var selectedRoles = new Array();
      Object.entries(this.props.roles).forEach(function(item, key){
        //console.log(item );
        selectedRoles.push(item[1]);
      })
      var rolesFilter = new Array();
      if(roles) {
        Object.entries(roles).forEach(function(item, key){
          var hasRole = component.props.roles.includes(item[1].id);
          var optionItem = {
            checked: (hasRole) ? true : false,
            text: item[1].label,
            value: item[1].id
          }
          rolesFilter.push(optionItem);
          //console.log(item);
        });
      }



      this.setState(prevState => ({
        uid: this.props.entity.uid[0].value,
        username: this.props.entity.name[0].value,
        mail: this.props.entity.mail[0].value,
        field_firstname: this.props.entity.field_firstname[0].value,
        field_lastname: this.props.entity.field_lastname[0].value,
        field_birthday: this.props.entity.field_birthday[0] ? new Date(this.props.entity.field_birthday[0].value) : new Date(Date.now()),
        field_street: this.props.entity.field_street[0].value,
        field_zip: this.props.entity.field_zip[0].value,
        field_city: this.props.entity.field_city[0].value,
        field_phone: this.props.entity.field_phone[0].value,
        field_mobile: this.props.entity.field_mobile[0].value,
        field_location: selectedLocations,
        field_login_days: selectedLoginDays,
        locationsFilter: locationsFilter,
        logindaysFilter: logindaysFilter,
        rolesFilter: rolesFilter,
        roles: selectedRoles
      }))
    }


  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }

    //console.log(e.target.value);

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    this.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      uid: this.state.uid,
      username: this.state.username,
      password: this.state.password,
      mail: this.state.mail,
      roles: this.state.roles,
      field_firstname: this.state.field_firstname,
      field_lastname: this.state.field_lastname,
      field_birthday: this.state.field_birthday,
      field_street: this.state.field_street,
      field_zip: this.state.field_zip,
      field_city: this.state.field_city,
      field_phone: this.state.field_phone,
      field_mobile: this.state.field_mobile,
      field_image: this.state.field_image,
      field_location: this.state.field_location,
      field_login_days: this.state.field_login_days
    }

    if(!this.state.uid) {
      EmployeeActions._post(values)
        .then(response => {
          if(response.data.status == 400) {
            alert(response.data.message);
          } else {
            component.props.searchComponent.loadEmployeeCollection();
            component._onFormReset();
            component._onToggleModal();
          }
        });
    } else {
      EmployeeActions._patch(values)
        .then(response => {
          if(response.data.status == 400) {
            alert(response.data.message);
          } else {
            component.props.searchComponent.loadEmployeeCollection();
            component._onToggleModal();
          }
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });

    //console.log(this.state);
  }

  _onChangeHandleFile = (file) => {
    var component = this;
    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         EmployeeActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);

    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }

    var roles = this.props.searchComponent.state.roles;
    var rolesFilter = new Array();
    if(roles) {
      Object.entries(roles).forEach(function(item, key){
        var optionItem = {
          text: item[1].label,
          value: item[1].id
        }
        rolesFilter.push(optionItem);
      });
    }

    var locations = this.props.searchComponent.state.locations;
    var locationsFilter = new Array();
    if(locations) {
      Object.entries(locations).forEach(function(item, key){
        var optionItem = {
          text: item[1].title[0].value,
          value: item[0]
        }
        locationsFilter.push(optionItem);
      });
    }


    this.setState(prevState => ({
      loaded: true,
      rolesFilter: rolesFilter,
      locationsFilter: locationsFilter,
      uid: false,
      username: '',
      password: '',
      mail: '',
      roles: [],
      field_firstname: '',
      field_lastname: '',
      field_birthday: new Date(Date.now()),
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_location: []
    }))
  }

  _onGetPickerValue = (value) => {
    //console.log(value);
    //var date = new Date(value).toJSON();
    this.setState({
      field_birthday: value
    });
  }

  render() {
    let component = this;
    return (
      <>
      {!this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Mitarbeiter erfassen</MDBBtn>
      }

      {this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal} size="lg">
        <MDBModalHeader toggle={this._onToggleModal}>Mitarbeiterverwaltung</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0'>


            <form onSubmit={this._onSubmit} id="employee-add-form">


              <MDBRow>
                <MDBCol size="12" className="hide">
                    <MDBFileupload
                        maxFileSize="16M"
                        ref={this.fileInputRef}
                        getValue={this._onChangeHandleFile}
                        />

                </MDBCol>
                <MDBCol size="12">

              <MDBRow>
                <MDBCol>
                  <MDBInput
                    name='field_firstname'
                    label='Vorname'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_firstname}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    name='field_lastname'
                    label='Name'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_lastname}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <div className="md-form form-group form-datepicker">
                    <MDBDatePicker cancelLabel="Abbrechen" value={this.state.field_birthday} name="field_birthday" getValue={this._onGetPickerValue} />
                    <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#4285f4" }}>Geburtstag</label>
                  </div>
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol>
                  <MDBInput
                    name='field_street'
                    label='Strasse'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_street}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    name='field_zip'
                    label='PLZ'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_zip}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    name='field_city'
                    label='Ort'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_city}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol>
                  <MDBInput
                    name='mail'
                    label='E-Mail'
                    group
                    type='email'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.mail}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    name='field_phone'
                    label='Telefon'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_phone}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    name='field_mobile'
                    label='Mobil'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_mobile}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol className="text-left">
                  <MDBSelect
                    required
                    ref={this.rolesFilterRef}
                    options={this.state.rolesFilter}
                    selected="Rollen auswählen"
                    label="Rollen"
                    name="roles"
                    id="roles"
                    multiple
                    getValue={(e) => {
                      this._onSelectChange({
                        target: {
                          name: 'roles',
                          value: e,
                        },
                      });
                    }}
                  />
                </MDBCol>
                <MDBCol className="text-left">
                  <MDBSelect
                    required
                    ref={this.locationsFilterRef}
                    options={this.state.locationsFilter}
                    selected="Standorte auswählen"
                    label="Standort"
                    name="field_location"
                    id="field_location"
                    multiple
                    getValue={(e) => {
                      this._onSelectChange({
                        target: {
                          name: 'field_location',
                          value: e,
                        },
                      });
                    }}
                  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol>
                  <MDBInput
                    name='username'
                    label='Benutzername'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.username}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>



                  {/*
                  {!this.state.entity &&
                    <div className="md-form form-group">
                      <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#4285f4" }}>Passwort</label>
                      <PasswordWithGenerator name='password' onBlur={this._onInputChange} onFocus={this._onInputChange} className="" required />
                    </div>
                  }

                  {this.state.entity &&
                    <div className="md-form form-group">
                      <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#4285f4" }}>Passwort</label>
                      <PasswordWithGenerator name='password' onBlur={this._onInputChange} onFocus={this._onInputChange} readOnly className="" />
                    </div>
                  }
                  */}



                  {!this.state.entity &&
                    <div className="md-form form-group">
                        <MDBInput
                          name='password'
                          label='Passwort'
                          group
                          type='password'
                          error='wrong'
                          success='right'
                          required
                          value={this.state.password}
                          onChange={this._onInputChange}
                        />
                    </div>
                  }

                  {this.state.entity &&
                    <div className="md-form form-group">
                        <MDBInput
                          name='password'
                          label='Passwort'
                          group
                          type='password'
                          error='wrong'
                          success='right'
                          value={this.state.password}
                          onChange={this._onInputChange}
                        />
                    </div>
                  }






                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol></MDBCol>
                  <MDBCol className="text-left">
                    <MDBSelect
                      required
                      ref={this.logindaysFilterRef}
                      options={this.state.logindaysFilter}
                      selected="Wochentage auswählen"
                      label="Wochentage"
                      name="field_login_days"
                      id="field_login_days"
                      multiple
                      getValue={(e) => {
                        this._onSelectChange({
                          target: {
                            name: 'field_login_days',
                            value: e,
                          },
                        });
                      }}
                    />
                  </MDBCol>
              </MDBRow>


                <MDBRow>
                  <MDBCol>
                    {!this.state.entity &&
                      <MDBBtn color="primary" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Reset</MDBBtn>
                    }
                    <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                  </MDBCol>
                </MDBRow>

            </MDBCol>
          </MDBRow>

            </form>

          </MDBCard>
        </MDBModalBody>
      </MDBModal>

      </>


    );
  }
}

export default EmployeeForm;
