import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBInput,
  MDBIcon,
  MDBView,
  MDBBtn,
  MDBAlert
} from 'mdbreact';
import { connect } from 'react-redux';
import userActions from '../../../actions/userActions';
import Logo from '../../../assets/images/veyu-logo.svg' // relative path to image



import '../../../assets/css/sections/login.css';


class Login extends React.Component {

  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      username: '',
      password: '',
      message: '',
      passwordId: Math.floor((Math.random() * 10000) + 1),
      usernameId: Math.floor((Math.random() * 10000) + 1)
    }
  }



  componentDidMount() {
    this.props.logout();
  }


  _onSubmit = event => {
    event.preventDefault();
    const { username, password } = this.state;
    if (username && password) {
      this.props.login(username, password);
    }
  }


  render() {

    const errorState  = this.props.authenticationReducer.error;
    //console.log(errorState);

    return (
      <div className='classic-form-page' id='login'>
        <MDBView>
          <MDBMask
            className='d-flex justify-content-center align-items-center'
            overlay='stylish-strong'
          >
            <MDBContainer>
              <MDBRow>
                <MDBCol md='10' lg='6' xl='5' sm='12' className='mt-5 mx-auto'>
                  <MDBCard>
                    <MDBCardBody>
                        <img src={Logo} className="img-fluid" />


                        {errorState && typeof errorState == "string" && false &&
                          <MDBAlert color="danger" >
                            {errorState}
                          </MDBAlert>
                        }




                        <form
                          className="needs-validation"
                          onSubmit={this._onSubmit}
                          autoComplete="none"
                          name="login-form"
                        >

                        <input type="hidden" value="prayer" />

                        <input id="username" className="hide" type="text" name="username" />
                        <input id="password" className="hide" type="password" name="password" />


                          <MDBInput
                            id={this.state.usernameId}
                            value={this.state.username}
                            type='text'
                            label='Benutzername'
                            name='username'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            required
                            autoComplete="off"
                          />
                          <MDBInput
                            id={this.state.passwordId}
                            value={this.state.password}
                            type='password'
                            label='Passwort'
                            name='password'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            required
                            autoComplete="new-password"
                          />

                          {!errorState &&
                            <div className='text-center'>
                            <div className="spinner-grow text-info" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            </div>
                          }

                          {errorState &&
                            <div className='text-center'>
                              <MDBBtn type="submit" size='lg'>
                                Login
                              </MDBBtn>
                            </div>
                          }


                        </form>


                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    );
  }
}

function mapState(state) {
  //console.log(state);
  const { authenticationReducer } = state;
  return { authenticationReducer };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(Login);
export { connectedLoginPage as Login };
