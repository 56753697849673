import React, { Component } from 'react';
import {
  MDBSideNavLink,
  MDBIcon,
  MDBBadge
} from 'mdbreact';

import PermissionHelper from "../../../helpers/PermissionHelper";


import TaskActions from "./actions/TaskActions";


// Import our config file.
import config from '../../../config.js';

// Load the `base` value from config and set it as a constant.
const DRUPAL_NOTIFY_ROOT = `${config.notify_base}`;

class TaskMenuLink extends Component {

  constructor(props) {
    super(props);

    this.state= {
      newTaskCounter: 0,
      filters: {
        field_status: null,
        field_employee: null,
        field_location: null,
      },
    }

    this._onClick = this._onClick.bind(this);
    this._onLoadTaskCounter = this._onLoadTaskCounter.bind(this);
  }

  componentDidMount() {
    this._onLoadTaskCounter();


    var component = this;
  }


  _onLoadTaskCounter() {
    var component = this;

    TaskActions._get('All', this.state.filters, 0).then(response => {
       console.log(response);

      var newTaskCounter = 0;
      if(response.data.total) {
        newTaskCounter = response.data.total;
      }


      component.setState({
        newTaskCounter: newTaskCounter
      });
    });
  }

  _onClick() {
    this._onLoadTaskCounter();
    this.props.onClick();
  }

  render() {
    return (
      <MDBSideNavLink topLevel to='/task' onClick={this._onClick}>
        <MDBIcon icon="clipboard-check mr-2" />
        Aufgaben
        {this.state.newTaskCounter > 0 &&
          <MDBBadge color="danger" className="ml-2 task-badge">{this.state.newTaskCounter}</MDBBadge>
        }
      </MDBSideNavLink>
    )
  }

}

export default TaskMenuLink;
