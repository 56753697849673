import React, { Component } from 'react';

class SessionParameter extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    //works for array
    var paramValues = Object.values(this.props.params);
    const renderParams = paramValues.map((item, i) => {
      var values = Object.values(item);
      var keys = Object.keys(item);
      return (<div key={i}><strong>{keys[0]}:</strong> {values[0]}<br /></div>);
    });

    return (
      <>
      {renderParams}
      </>
    );
  }
}


export default SessionParameter;
