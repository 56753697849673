import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';

import { Editor } from '@tinymce/tinymce-react';

import NewsActions from "./actions/NewsActions";
import Image from "../image/Image";

import moment from 'moment';
import 'moment/locale/de';


class NewsForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entity: this.props.entity,
      modal: false,
      nid: false,
      title: '',
      body: '',
      field_news_category: '',
      field_location: '',
      field_image: false,
      newsCategoriesFilter: this.props.searchComponent.state.newsCategoriesFilter,
      locationsFilter: this.props.searchComponent.state.locationsFilter,
      loaded: true
    }

    this.locationFilterRef = React.createRef();
    this.newsCategoryFilterRef = React.createRef();
    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onHandleEditorChange = this._onHandleEditorChange.bind(this);
  }

  componentDidMount() {

    let component = this;
    if(this.props.entity) {
      var selectedLocations = new Array();
      Object.entries(this.props.locations).forEach(function(item, key){
        selectedLocations.push(item[1].nid[0].value.toString());
      })
      var locations = this.props.searchComponent.state.locations;
      var locationsFilter = new Array();
      if(locations) {
        Object.entries(locations).forEach(function(item, key){
          var hasLocation = selectedLocations.includes(item[0].toString());
          var optionItem = {
            checked: (hasLocation) ? true : false,
            text: item[1].title[0].value,
            value: item[0]
          }
          locationsFilter.push(optionItem);
          //console.log(item);
        });
      }


      var newsCategories = this.props.searchComponent.state.newsCategories;
      var newsCategoriesFilter = new Array();
      if(newsCategories) {
        Object.entries(newsCategories).forEach(function(item, key){
          var optionItem = {
            checked: (item[0] == component.props.entity.field_news_category[0].target_id) ? true : false,
            text: item[1].name[0].value,
            value: item[0]
          }
          newsCategoriesFilter.push(optionItem);
          //console.log(item);
        });
      }


      this.setState(prevState => ({
        nid: this.props.entity.nid[0].value,
        title: this.props.entity.title[0].value,
        body: this.props.entity.body[0].value,
        field_news_category: [this.props.entity.field_news_category[0].target_id],
        field_location: [this.props.entity.field_location[0].target_id],
        locationsFilter: locationsFilter,
        newsCategoriesFilter: newsCategoriesFilter
      }))
    }
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    this.setState(prevState => ({
      loaded: false,
    }))


    if(!this.state.body) {
      alert('Bitte gib eine Newstext ein.');
      return;
    }


    if(!this.state.nid) {
      if(!this.state.field_image) {
        alert('Bitte lade ein Bild hoch.');
        return;
      }
    }


    let values = {
      nid: this.state.nid,
      title: this.state.title,
      body: this.state.body,
      field_image: this.state.field_image,
      field_news_category: this.state.field_news_category,
      field_location: this.state.field_location
    }

    if(!this.state.nid) {
      NewsActions._post(values)
        .then(response => {
          component.props.searchComponent.loadNewsCollection();
          component._onToggleModal();
          component._onFormReset();
        });
    } else {
      NewsActions._patch(values)
        .then(response => {
          component.props.searchComponent.loadNewsCollection();
          component._onToggleModal();
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onChangeHandleFile = (file) => {
    var component = this;

    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         NewsActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);


    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }

    var newsCategories = this.props.searchComponent.state.newsCategories;
    var newsCategoriesFilter = new Array();
    if(newsCategories) {
      Object.entries(newsCategories).forEach(function(item, key){
        var optionItem = {
          text: item[1].name[0].value,
          value: item[0]
        }
        newsCategoriesFilter.push(optionItem);
      });
    }

    var locations = this.props.searchComponent.state.locations;
    var locationsFilter = new Array();
    if(locations) {
      Object.entries(locations).forEach(function(item, key){
        var optionItem = {
          text: item[1].title[0].value,
          value: item[0]
        }
        locationsFilter.push(optionItem);
      });
    }

    this.setState(prevState => ({
      loaded: true,
      nid: false,
      title: '',
      body: '',
      field_news_category: '',
      field_location: '',
      field_image: false,
      newsCategoriesFilter: newsCategoriesFilter,
      locationsFilter: locationsFilter
    }))
  }

  _onHandleEditorChange = (content, editor) => {
     //console.log('Content was updated:', content);
     this.setState({
       body: content,
     });
   }

  render() {

    let component = this;
    return (
      <>
      {!this.state.entity &&
        <>
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Artikel erfassen</MDBBtn>
          <hr />
        </>
      }

      {this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


      <MDBModal size="lg" isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Newsverwaltung</MDBModalHeader>
        <MDBModalBody>
          <form id="news-add-form" onSubmit={this._onSubmit}>
            <MDBRow>
              <MDBCol size="12">
                  <MDBFileupload
                      maxFileSize="16M"
                      ref={this.fileInputRef}
                      getValue={this._onChangeHandleFile}
                      />

              </MDBCol>
              <MDBCol size="12">

                <MDBRow>
                  <MDBCol>
                    <MDBInput label="Titel" name="title" id="title" onChange={this._onInputChange} value={this.state.title} required />
                  </MDBCol>
                  <MDBCol>
                    <MDBSelect
                      required
                      ref={this.locationFilterRef}
                      options={this.state.locationsFilter}
                      selected="Bitte auswählen"
                      label="Standort"
                      multiple
                      name="field_location"
                      id="field_location"
                      getValue={(e) => {
                        this._onSelectChange({
                          target: {
                            name: 'field_location',
                            value: e,
                          },
                        });
                      }}
                    />
                  </MDBCol>
                  <MDBCol>
                    <MDBSelect
                      required
                      ref={this.newsCategoryFilterRef}
                      options={this.state.newsCategoriesFilter}
                      selected="Bitte auswählen"
                      label="Kategorie"
                      name="field_news_category"
                      id="field_news_category"
                      getValue={(e) => {
                        this._onSelectChange({
                          target: {
                            name: 'field_news_category',
                            value: e,
                          },
                        });
                      }}
                    />
                  </MDBCol>
                </MDBRow>


                <Editor
                 required
                 apiKey='2es7wv2n49blwza8a9hwyax3y4cov70297nz2yeuwq4w3dad'
                 value={this.state.body}
                 initialValue={this.state.body}
                 init={{
                   height: 170,
                   menubar: false,
                   plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code help wordcount'
                   ],
                   toolbar:
                     'undo redo | formatselect | bold italic backcolor | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat | help'
                 }}
                 onEditorChange={this._onHandleEditorChange}
               />

             <hr />

               <MDBRow>
                 <MDBCol>
                   {!this.state.entity &&
                     <MDBBtn color="primary" className="mr-2" onClick={this._onFormReset}><MDBIcon icon="sync mr-2" /> Reset</MDBBtn>
                   }

                   <MDBBtn color="primary" disabled={( (!this.state.field_image) && !this.state.entity)  ? true : false} type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>


                 </MDBCol>
               </MDBRow>

              </MDBCol>
            </MDBRow>
          </form>
        </MDBModalBody>
      </MDBModal>
      </>

    );
  }
}

export default NewsForm;
