import React, {
  Component
} from 'react';

import RecommendationResultRow from "./RecommendationResultRow";
import Pager from 'react-pager';
import PermissionHelper from "../../../helpers/PermissionHelper";
import RecommendationActions from "./actions/RecommendationActions";
import RecommendationForm from "./RecommendationForm";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBBadge,
  MDBChip,
  MDBLink,
  MDBNavLink
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';


import config from '../../../config';



class RecommendationSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      nid: false,
      result: [],
      customers: [],
      recommendationFrom: false,
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_recommended: this.props.nid,
        field_customer: this.props.nid,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc'
      },
      sortOptions: [
        {
          checked: true,
          text: 'Geändert',
          value: 'nfd.changed'
        }
      ]
    }

    this.formRef = React.createRef();
    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  componentDidMount() {

    console.log(this.props);

    this.loadRecommendationCollection();
  }




  loadRecommendationCollection() {

      var component = this;


      component.setState({
        collectionLoaded: false
      });


      /** build filter query **/
      RecommendationActions._get('All', this.state.filters, this.state.page).then(response => {

        component.setState({
          result: response.data.result,
          customers: response.data.customers,
          recommendationFrom: response.data.recommendationFrom,
          unusedRecommendations: response.data.unusedRecommendations,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });



      });


  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadRecommendationCollection();
    });
  }

  render() {
    let component = this;



    return (
      <>

        {component.props.mode == 'link' &&
          <>
            <MDBNavLink link to="#" active={component.props.customerDetail.state.activeItem === "5"} onClick={component.props.customerDetail._onTabToggle("5")} role="tab">
              Empfehlungen
              {component.state.unusedRecommendations > 0 &&
                <MDBBadge color="danger" className="ml-2">{component.state.unusedRecommendations}</MDBBadge>
              }

            </MDBNavLink>
          </>
        }

        {component.props.mode == 'tabcontent' &&
        <section id="recommendation-bar">

          {!this.state.loaded &&
            <div className="text-center">
              <MDBSpinner red />
            </div>
          }

          {this.state.loaded &&  (
            <>

              {this.state.customers && !this.state.recommendationFrom[0] &&
                <>
                {/*
                  <RecommendationForm customers={this.state.customers} searchComponent={this} ref={this.formRef} nid={this.props.nid} />
                  <hr />
                */}

                </>
              }





              {this.state.recommendationFrom[0] &&
                <>
                  <h2>
                      Wurde empfohlen von: <a href={'/customer/' + this.state.recommendationFrom[0].recommended.nid[0].value}>{this.state.recommendationFrom[0].recommended.title[0].value}</a>
                  </h2>
                  <hr />
                </>
              }



              <h2>Von mir Empfohlene Kunden</h2>

              { component.state.result.length > 0 && (
                <div>
                <MDBTable responsive hover striped>
                  <thead>
                    <tr>
                      <th className='th-lg'>
                        Empfehlungs-Nr. #
                      </th>
                      <th className='th-lg'>
                        Erstellt am
                      </th>
                      <th className='th-lg'>
                        Eingelöst am
                      </th>
                      <th className='th-lg hide'>
                        Bereits verwendet?
                      </th>
                      <th className='th-lg'>
                        Kunde
                      </th>
                      {/*<th>Bemerkung</th>*/}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {true && (
                      component.state.result.map(function(row, j){
                        console.log(row)
                        return (
                          <RecommendationResultRow result={row}  key={'recommendation-result-view-' + row.nid + row.changed} searchComponent={component} />
                        )
                      })
                    )}
                  </tbody>
                </MDBTable>

                {component.state.unusedRecommendations > 0 &&
                  <h3>Aktueller Rabatt: {component.state.total - component.state.unusedRecommendations + 1 + '0%'}</h3>
                }

                </div>
              )}

              {component.state.result.length == 0 &&
                <div className="empty">Keine Empfehlungen</div>
              }

              {component.state.loaded && component.state.total > 10 && (
                <>
                  <hr />
                  <Pager
                        total={Math.ceil(this.state.total / this.state.limit)}
                        current={this.state.page}
                        visiblePages={this.state.visiblePage}
                        titles={{ first: '<|', last: '>|' }}
                        className="pagination-sm pull-right"
                        onPageChanged={this._onHandlePageChanged}
                    />
                </>
              )}
            </>
          )}
        </section>
        }
      </>
    );
  }
}

export default RecommendationSearch;
