import React, { Component } from 'react';
import {
  MDBCard,
  MDBSpinner,
  MDBLink,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBAnimation,
  MDBBadge
} from 'mdbreact';
import CustomerComment from "../comment/CommentSearch";
import CustomerReviewComment from "../comment/CommentReviewSearch";
import CustomerScan from "../scan/ScanSearch";
import TreatmentSearch from "../treatment/TreatmentSearch";
import RecommendationSearch from "../recommendation/RecommendationSearch";
import CustomerGallery from "../gallery/GallerySearch";
import CustomerActions from './actions/CustomerActions';
import CustomerForm from './CustomerForm';
import PermissionHelper from "../../../helpers/PermissionHelper";
import { history } from '../../../helpers/history';

import NumberFormat from 'react-number-format';


import moment from 'moment';
import 'moment/locale/de';

/** import styles **/
import '../../../assets/css/sections/customer.css';



class CustomerDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeItem: "1",
      loaded: false,
      entity: null,
      locations: [],
      genders: [],
      countImportant: 0,
      countReviews: 0,
      nid: this.props.match.params.id,
      channel: ''
    };


    this.customerCommentRef = React.createRef();
    this.customerReviewCommentRef = React.createRef();

    this._onDelete = this._onDelete.bind(this);
    this._onTabToggle = this._onTabToggle.bind(this);
  }

  componentDidMount() {
    CustomerActions._get(this.props.match.params.id).then(response => {
      //console.log(response);
      this.setState({
        entity: response.data.entity,
        locations: response.data.locations,
        locationsFilter: response.data.locationsFilter,
        genders: response.data.genders,
        image: response.data.image,
        channelOptions: response.data.channelOptions,
        channel: response.data.channel,
        loaded: true
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
         /**
         * this is the initial render
         * without a previous prop change
         */
        if(prevProps == undefined) {
            return false
        }

        /**
         * new Project in town ?
         */
        if (this.state.nid != this.props.match.params.id) {
           this.setState({nid: this.props.match.params.id});
           this.componentDidMount();
        }

    }

  _onTabToggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  _onDelete() {
    const r = window.confirm("Löschen Bestätigen");
    if(r === true){
      this.setState({
        loaded: false
      });
      CustomerActions._delete(this.props.match.params.id).then(response => {
        history.push('/customer');
      });
    }
  }

  render() {

    let component = this;

    return (
      <section id="customer-detail" {...(this.state.loaded ? {key: this.state.entity.changed[0].value} : {})}>

        {!this.state.loaded &&
          <div className="text-center">
            <MDBSpinner red />
          </div>
        }

        {this.state.loaded &&
            <MDBCard>
              <MDBCardBody tag="div">
                <MDBCardTitle>
                  <MDBRow>
                    <MDBCol size="6" className="">
                      <MDBIcon icon="users mr-2" /> {this.state.entity.field_firstname[0].value} {this.state.entity.field_lastname[0].value}
                    </MDBCol>
                    <MDBCol size="6" className="text-right">

                      <div className="action-bar">
                        <MDBNavLink to={"/customer"} className="mr-2">
                          <MDBBtn size="sm" color="info">
                            <MDBIcon icon="chevron-left" />
                          </MDBBtn>
                        </MDBNavLink>



                        <CustomerForm  key={'customer-edit-form-' + this.state.entity.nid[0].value + this.state.entity.changed[0].value} entity={this.state.entity} locations={this.state.locations} locationsFilter={this.state.locationsFilter} channelOptions={this.state.channelOptions} genders={this.state.genders} customerDetail={this}  />

                      {PermissionHelper._hasPermission('restful delete Customer') === true &&
                        <>
                            <MDBBtn size="sm" color="danger" onClick={this._onDelete}>
                              <MDBIcon far icon="trash-alt" />
                            </MDBBtn>
                        </>
                      }

                      </div>


                    </MDBCol>
                  </MDBRow>
                </MDBCardTitle>
                <MDBCardText tag="div">
                <div className="row">
                  <div className="col-md-4">
                    <h3>Persönliche Daten</h3>
                    <div className="item">
                      <div className="label">Anrede</div>
                      <div className="value">
                        {this.state.entity.field_gender[0] &&
                          this.state.genders.map(function(row, j){
                            //console.log(row);
                            if(row.id == component.state.entity.field_gender[0].target_id) {
                              return row.name;
                            }
                          })
                        }

                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Vorname</div>
                      <div className="value">
                        {this.state.entity.field_firstname[0] &&
                          <>{this.state.entity.field_firstname[0].value}</>
                        }
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Nachname</div>
                      <div className="value">
                        {this.state.entity.field_lastname[0] &&
                          <>{this.state.entity.field_lastname[0].value}</>
                        }
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Beruf</div>
                        <div className="value">
                          {this.state.entity.field_job[0] &&
                            <>{this.state.entity.field_job[0].value}</>
                          }
                        </div>
                    </div>
                    <div className="item">
                      <div className="label">Geburtstag</div>
                        <div className="value">
                          {this.state.entity.field_birthday[0] &&
                            <>{moment(this.state.entity.field_birthday[0].value).format('DD.MM.YYYY')}</>
                          }
                        </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h3>Kontaktdaten</h3>
                    <div className="item">
                      <div className="label">E-Mail</div>
                        <div className="value">
                          {this.state.entity.field_email[0] &&
                            <>{this.state.entity.field_email[0].value}</>
                          }
                        </div>
                    </div>
                    <div className="item">
                      <div className="label">Festnetz</div>
                        <div className="value">
                          {this.state.entity.field_phone[0] &&
                            <><NumberFormat displayType={'text'} format="+## ## ### ## ##" mask="_" value={this.state.entity.field_phone[0].value}/></>
                          }
                        </div>
                    </div>
                    <div className="item">
                      <div className="label">Mobile</div>
                        <div className="value">
                          {this.state.entity.field_mobile[0] &&
                            <><NumberFormat displayType={'text'} format="+## ## ### ## ##" mask="_" value={this.state.entity.field_mobile[0].value}/></>
                          }
                        </div>
                    </div>
                    <div className="item">
                      <div className="label">Adresse</div>

                        <div className="value">
                          {this.state.entity.field_street[0] &&
                            <>{this.state.entity.field_street[0].value}<br /></>
                          }
                          {this.state.entity.field_zip[0] &&
                            <>{this.state.entity.field_zip[0].value} </>
                          }
                          {this.state.entity.field_city[0] &&
                            <>{this.state.entity.field_city[0].value}</>
                          }
                        </div>


                    </div>
                  </div>
                  <div className="col-md-4">
                    <h3>Interna</h3>
                      <div className="item">
                        <div className="label">Kundennummer</div>
                        <div className="value">{this.state.entity.field_customer_number[0].value}</div>
                      </div>
                      {this.state.entity.field_channel[0] &&
                        <div className="item">
                          <div className="label">Channel</div>
                          <div className="value">{this.state.channel}</div>
                        </div>
                      }
                      {this.state.entity.field_channel[0] && this.state.entity.field_channel[0].target_id == 32 &&
                        <div className="item">
                          <div className="label">Channel</div>
                          <div className="value">{this.state.entity.field_channel_other[0].value}</div>
                        </div>
                      }
                      <div className="item">
                        <div className="label">Standorte</div>
                        <div className="value">
                          {true && (
                            this.state.locations.map(function(row, j){
                              //console.log(row)
                              return (
                                <span key={'location-' + j }>{row.title[0].value}, </span>
                              )
                            })
                          )}
                        </div>
                      </div>
                  </div>
                </div>
                <div className="customer-tabs">
                  <MDBNav tabs>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this._onTabToggle("1")} role="tab">Behandlungen</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this._onTabToggle("2")} role="tab">Scans</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this._onTabToggle("3")} role="tab">
                        Bemerkungen
                        {this.state.countImportant > 0 &&
                          <MDBBadge color="danger" className="ml-2">{this.state.countImportant}</MDBBadge>
                        }
                    </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this._onTabToggle("4")} role="tab"> Galerie</MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <RecommendationSearch nid={this.state.entity.nid[0].value} mode="link" customerDetail={component} />
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "6"} onClick={this._onTabToggle("6")} role="tab">
                        Bewertungen
                        {this.state.countReviews == 0 &&
                          <MDBBadge color="danger" className="ml-2">!</MDBBadge>
                        }
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "7"} onClick={this._onTabToggle("7")} role="tab">Vereinbarungen</MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>
                  <MDBTabContent className="card-no" activeItem={this.state.activeItem}>
                    <MDBTabPane tabId="1" role="tabpanel">
                      <hr />
                      <TreatmentSearch nid={this.state.entity.nid[0].value} />
                    </MDBTabPane>
                    <MDBTabPane tabId="2" role="tabpanel">
                      <hr />
                      <CustomerScan nid={this.state.entity.nid[0].value} />
                    </MDBTabPane>
                    <MDBTabPane id="customer-comment" tabId="3" role="tabpanel">
                      <hr />
                      <CustomerComment showImportant={true} parentComponent={component} ref={this.customerCommentRef} entityId={this.state.entity.nid[0].value} entityType="node" fieldName="field_customer_comment" commentType="customer_comment" />
                    </MDBTabPane>
                    <MDBTabPane id="customer-gallery" tabId="4" role="tabpanel">
                      <hr />
                      <CustomerGallery nid={this.state.entity.nid[0].value} />
                    </MDBTabPane>
                    <MDBTabPane id="customer-gallery" tabId="5" role="tabpanel">
                      <hr />
                      <RecommendationSearch nid={this.state.entity.nid[0].value} mode="tabcontent" customerDetail={component} />
                    </MDBTabPane>
                    <MDBTabPane id="customer-comment" tabId="6" role="tabpanel">
                      <hr />
                      <CustomerReviewComment showImportant={true} parentComponent={component} ref={this.customerReviewCommentRef} entityId={this.state.entity.nid[0].value} entityType="node" fieldName="field_customer_review_comment" commentType="customer_review_comment" />
                    </MDBTabPane>
                    <MDBTabPane id="agreement-tab" tabId="7" role="tabpanel">
                      <hr />
                      <h1>Vereinbarungen</h1>
                    </MDBTabPane>
                  </MDBTabContent>
                </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
        }
      </section>
    );
  }
}

//export default Customers;
export default CustomerDetail;
