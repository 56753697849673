import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';
import CustomerActions from './actions/CustomerActions';
import CustomerRecommendationSearch from './CustomerRecommendationSearch';
import { history } from '../../../helpers/history';

import MDBFileupload from 'mdb-react-fileupload';

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';

import Image from "../image/Image";

class CustomerForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      modal: false,
      nid: false,
      entity: this.props.entity,
      locationsFilter: this.props.locationsFilter,
      genderFilters: this.props.genderFilters,
      channelOptions: this.props.channelOptions,
      loaded: true,
      field_firstname: '',
      field_lastname: '',
      field_birthday: new Date(Date.now()),
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_image: '',
      field_job: '',
      field_location: [],
      field_gender: [],
      field_channel: null,
      field_channel_other: null,
      field_selected_recommendation_customer: null
    }

    this.locationFilterRef = React.createRef();
    this.rolesFilterRef = React.createRef();
    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onGetPickerValue = this._onGetPickerValue.bind(this);
    this._onPreventToggle = this._onPreventToggle.bind(this);
    this._onChannelChange = this._onChannelChange.bind(this);
  }

  componentDidMount() {

    var component = this;

    if(this.props.entity) {



      //console.log(this.props.locations);
      var selectedLocations = new Array();
      Object.entries(this.props.locations).forEach(function(item, key){
        selectedLocations.push(item[1].nid[0].value.toString());
      })
      var locations = this.props.locationsFilter;
      //console.log(locations);
      var locationsFilter = new Array();
      if(locations) {
        Object.entries(locations).forEach(function(item, key){
          var hasLocation = selectedLocations.includes(item[0].toString());
          var optionItem = {
            checked: (hasLocation) ? true : false,
            text: item[1].title[0].value,
            value: item[0]
          }
          locationsFilter.push(optionItem);
          //console.log(item);
        });
      }



      var selectedGender = new Array();
      if(this.props.entity.field_gender[0]) {
        selectedGender.push(this.props.entity.field_gender[0].target_id.toString());
      }

      var genders = this.props.genders;
      //console.log(genders);
      //console.log(selectedGender);
      var genderFilters = new Array();
      if(genders) {
        Object.entries(genders).forEach(function(item, key){
          //console.log(item);
          var hasGender = selectedGender.includes(item[1].id.toString());
          var optionItem = {
            checked: (hasGender) ? true : false,
            text: item[1].name,
            value: item[1].id,
          }
          genderFilters.push(optionItem);
          //console.log(item);
        });
      }




      //console.log(this.props.locations);
      var selectedChannels = new Array();
      if(this.props.entity.field_channel[0]) {
        //console.log(this.props.entity.field_channel);
        selectedChannels.push(this.props.entity.field_channel[0].target_id.toString());
      }
      var channels = this.props.channelOptions;
      //console.log(locations);
      var channelOptions = new Array();
      if(channels) {
        Object.entries(channels).forEach(function(item, key){
          //console.log(item);
          var hasChannel = selectedChannels.includes(item[1].value.toString());
          var optionItem = {
            checked: (hasChannel) ? true : false,
            text: item[1].text,
            value: item[1].value
          }
          channelOptions.push(optionItem);
          //console.log(item);
        });
      }



    //  console.log(this.props.entity.field_channel[0]);

      this.setState(prevState => ({
        loaded: true,
        nid: this.props.entity.nid[0].value,
        field_firstname: this.props.entity.field_firstname[0] ? this.props.entity.field_firstname[0].value : '',
        field_lastname: this.props.entity.field_lastname[0] ? this.props.entity.field_lastname[0].value : '',
        field_birthday: this.props.entity.field_birthday[0] ? this.props.entity.field_birthday[0].value : new Date(Date.now()),
        field_street: this.props.entity.field_street[0] ? this.props.entity.field_street[0].value : '',
        field_zip: this.props.entity.field_zip[0] ? this.props.entity.field_zip[0].value : '',
        field_city: this.props.entity.field_city[0] ? this.props.entity.field_city[0].value : '',
        field_phone: this.props.entity.field_phone[0] ? this.props.entity.field_phone[0].value : '',
        field_mobile: this.props.entity.field_mobile[0] ? this.props.entity.field_mobile[0].value : '',
        field_email: this.props.entity.field_email[0] ? this.props.entity.field_email[0].value : '',
        field_location: selectedLocations,
        field_gender: selectedGender,
        field_job: this.props.entity.field_job[0] ? this.props.entity.field_job[0].value : '',
        field_channel: selectedChannels,
        field_channel_other: this.props.entity.field_channel_other[0] ? this.props.entity.field_channel_other[0].value : '',
        locationsFilter: locationsFilter,
        genderFilters: genderFilters,
        channelOptions: channelOptions,
      }))



    } else {

      if(PermissionHelper._hasPermission('ignore locations') === false) {
        var selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
        this.setState({
          field_location: [selectedLocation]
        });
      }

      component.setState({
        channelOptions: this.props.searchComponent.state.channelOptions
      });

    }



  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onSubmit(e) {
    e.preventDefault();
    var component = this;


    if(component.state.hasSubmitted) {
      return;
    }

    component.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))

    let values = {
      nid: this.state.nid,
      field_firstname: this.state.field_firstname,
      field_lastname: this.state.field_lastname,
      field_birthday: this.state.field_birthday,
      field_street: this.state.field_street,
      field_zip: this.state.field_zip,
      field_city: this.state.field_city,
      field_phone: this.state.field_phone,
      field_mobile: this.state.field_mobile,
      field_email: this.state.field_email,
      field_image: this.state.field_image,
      field_job: this.state.field_job,
      field_location: this.state.field_location,
      field_gender: this.state.field_gender,
      field_channel: this.state.field_channel,
      field_channel_other: this.state.field_channel_other,
      field_selected_recommendation_customer: this.state.field_selected_recommendation_customer,
    }

    if(!this.state.nid) {
      CustomerActions._post(values)
        .then(response => {


          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))

          component._onToggleModal();
          component._onFormReset();

          if(component.props.searchComponent) {
            component.props.searchComponent.loadCustomerCollection();
          }

          if(component.props.customerDetail) {
            component.props.customerDetail.componentDidMount();
          }
        });
    } else {
      CustomerActions._patch(values)
        .then(response => {

          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))

          component._onToggleModal();
          if(component.props.searchComponent) {
            component.props.searchComponent.loadCustomerCollection();
          }

          if(component.props.customerDetail) {
            component.props.customerDetail.componentDidMount();
          }
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onChangeHandleFile = (file) => {
    var component = this;
    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         CustomerActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);

    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }
    var genders = this.props.genders;
    var genderFilters = new Array();
    if(genders) {
      Object.entries(genders).forEach(function(item, key){
        //console.log(item);
        var optionItem = {
          text: item[1].name,
          value: item[1].id,
        }
        genderFilters.push(optionItem);
      });
    }

    var locations = this.props.locations;
    var locationsFilter = new Array();
    if(locations) {
      Object.entries(locations).forEach(function(item, key){
        var optionItem = {
          text: item[1].title[0].value,
          value: item[0]
        }
        locationsFilter.push(optionItem);
      });
    }


    this.setState(prevState => ({
      loaded: true,
      genderFilters: genderFilters,
      locationsFilter: locationsFilter,
      nid: false,
      field_firstname: '',
      field_lastname: '',
      field_birthday: new Date(Date.now()),
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_email: '',
      field_image: '',
      field_job: '',
      field_location: [],
      field_gender: []
    }))
  }

  _onGetPickerValue = (value) => {
    //console.log(value);
    //var date = new Date(value).toJSON();
    this.setState({
      field_birthday: value
    });
  }

  _onPreventToggle() {
    return false;
  }

  _onChannelChange(value) {

    this.setState({
      field_channel: value
    });
  };

  render() {

    console.log(this.state);

    let component = this;


    let showChannelField = false;
    if(!component.state.entity || component.state.entity.field_channel.length == 0 || component.state.entity.field_channel[0].value != "29") {
      showChannelField = true;
    }

    showChannelField = true;


    let showChannelOtherField = false;
    if(component.state.field_channel == '32' || component.state.field_channel_other) {
      showChannelOtherField = true;
    }

    let showChannelCustomerField = false;
    if(component.state.field_channel == '29') {
      showChannelCustomerField = true;
    }

    return (
      <>
        {!this.state.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Kunden erfassen</MDBBtn>
        }

        {this.state.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <MDBModal isOpen={this.state.modal} toggle={this._onPreventToggle} size="lg">
          <MDBModalHeader toggle={this._onToggleModal}>Kundenverwaltung</MDBModalHeader>
          <MDBModalBody>
            <MDBCard className='mt-0'>


                <form validate="true" onSubmit={this._onSubmit}>

                  <MDBRow>
                    <MDBCol size="12" className="hide">
                        <MDBFileupload
                            maxFileSize="16M"
                            ref={this.fileInputRef}
                            getValue={this._onChangeHandleFile}
                            />

                    </MDBCol>
                    <MDBCol size="12">

                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            name='field_firstname'
                            label='Vorname'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_firstname}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            name='field_lastname'
                            label='Name'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_lastname}
                          />
                        </MDBCol>
                      </MDBRow>



                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            name='field_street'
                            label='Strasse'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_street}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            name='field_zip'
                            label='PLZ'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_zip}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            name='field_city'
                            label='Ort'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            required
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_city}
                          />
                        </MDBCol>
                      </MDBRow>


                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            name='field_email'
                            label='E-Mail'
                            group
                            type='email'
                            error='wrong'
                            success='right'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_email}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            name='field_phone'
                            label='Festnetz'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_phone}
                          />
                        </MDBCol>
                        <MDBCol>
                          <MDBInput
                            name='field_mobile'
                            label='Mobil'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_mobile}
                          />
                        </MDBCol>
                      </MDBRow>


                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            name='field_job'
                            label='Job'
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            onChange={e => this.setState({ [e.target.name]: e.target.value })}
                            value={this.state.field_job}
                          />
                        </MDBCol>
                        <MDBCol>
                          <div className="md-form form-group form-datepicker">
                            <MDBDatePicker id="field_birthday" name="field_birthday" required cancelLabel="Abbrechen" locale={moment.locale('de')} value={this.state.field_birthday} getValue={this._onGetPickerValue} />
                            <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#51697A" }}>Geburtstag</label>
                          </div>
                        </MDBCol>
                      </MDBRow>


                      <MDBRow>
                        <MDBCol className="text-left">
                          <MDBSelect
                            required
                            ref={this.genderFiltersRef}
                            options={this.state.genderFilters}
                            selected="Bitte auswählen"
                            label="Geschlecht"
                            name="field_gender"
                            id="field_gender"
                            getValue={(e) => {
                              this._onSelectChange({
                                target: {
                                  name: 'field_gender',
                                  value: e,
                                },
                              });
                            }}
                          />
                        </MDBCol>
                        <MDBCol className="text-left">

                          {PermissionHelper._hasPermission('ignore locations') === true &&
                            <MDBSelect
                              required
                              ref={this.locationsFilterRef}
                              options={this.state.locationsFilter}
                              selected="Bitte auswählen"
                              label="Standort"
                              name="field_location"
                              id="field_location"
                              multiple
                              getValue={(e) => {
                                this._onSelectChange({
                                  target: {
                                    name: 'field_location',
                                    value: e,
                                  },
                                });
                              }}
                            />
                          }


                        </MDBCol>
                      </MDBRow>




                      {showChannelField &&
                        <MDBRow>
                          <MDBCol className="text-left">
                            <MDBSelect
                              selected="Bitte auswählen"
                              name="field_channel"
                              options={this.state.channelOptions}
                              error='wrong'
                              success='right'
                              required
                              getValue={this._onChannelChange}
                              onChange={this._onChannelChange}
                              label="Channel"
                            />
                          </MDBCol>
                        </MDBRow>
                      }


                      {showChannelOtherField && showChannelField &&
                        <MDBRow>
                          <MDBCol className="text-left">
                            <MDBInput id="field_channel_other" name="field_channel_other" value={component.state.field_channel_other} onChange={component._onInputChange} label="Andere" required />
                          </MDBCol>
                        </MDBRow>
                      }



                      {showChannelCustomerField &&
                        <MDBRow>
                          <MDBCol className="text-left">
                            <CustomerRecommendationSearch form={this} />
                          </MDBCol>
                        </MDBRow>
                      }



                      <MDBRow>
                        <MDBCol>
                          {!this.state.entity &&
                            <MDBBtn color="primary" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Reset</MDBBtn>
                          }

                          <MDBBtn color="primary" type="submit" disabled={((this.state.hasSubmitted) || (!this.state.nid && this.state.field_channel == 29 && !this.state.field_selected_recommendation_customer)) ? true : false}><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                        </MDBCol>
                      </MDBRow>




                    </MDBCol>
                  </MDBRow>
                </form>
            </MDBCard>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}

export default CustomerForm;
