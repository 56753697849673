import React, { Component } from 'react';

import SessionResultRowPdf from "./SessionResultRowPdf";
import SessionForm from "./SessionForm";

import SessionActions from "./actions/SessionActions";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';

import {
  MDBTable
} from 'mdbreact';

class SessionPdfList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      treatmentType: null,
      filters: {
        field_treatment: this.props.nid
      }
    }
  }


  componentDidMount() {
    this.loadSessionCollection();
  }


  loadSessionCollection() {
      var component = this;

      /** build filter query **/
      SessionActions._get('All', this.state.filters, this.state.page).then(response => {

        //console.log(response);

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        var treatmentType = null;;
        if(response.data.treatmentType) {
          treatmentType = response.data.treatmentType;
        }

        //console.log(treatmentType);

        component.setState({
          result: result,
          loaded: true,
          treatmentType: treatmentType,
          total: response.data.total
        });

      });
  }


  toUTF8Array(str) {
      var utf8 = [];
      for (var i=0; i < str.length; i++) {
          var charcode = str.charCodeAt(i);
          if (charcode < 0x80) utf8.push(charcode);
          else if (charcode < 0x800) {
              utf8.push(0xc0 | (charcode >> 6),
                        0x80 | (charcode & 0x3f));
          }
          else if (charcode < 0xd800 || charcode >= 0xe000) {
              utf8.push(0xe0 | (charcode >> 12),
                        0x80 | ((charcode>>6) & 0x3f),
                        0x80 | (charcode & 0x3f));
          }
          // surrogate pair
          else {
              i++;
              charcode = ((charcode&0x3ff)<<10)|(str.charCodeAt(i)&0x3ff)
              utf8.push(0xf0 | (charcode >>18),
                        0x80 | ((charcode>>12) & 0x3f),
                        0x80 | ((charcode>>6) & 0x3f),
                        0x80 | (charcode & 0x3f));
          }
      }
      return utf8;
  }


  render() {

    let component = this;

    //console.log(component.state.result);

    if(component.state.result[0]) {
      var paramValues = Object.values(JSON.parse(component.state.result[0][1][0].node.field_parameters[0].value));
    } else {
      var paramValues = [];
    }

    const renderParams = paramValues.map((item, i) => {
      var values = Object.values(item);
      var keys = Object.keys(item);
      //console.log(keys[0].trim());

      var label = keys[0];
      label = label.trim();
       //label = this.toUTF8Array(label);
      //label = decodeURIComponent( escape( label ) );

      return (<th>{label}</th>);
    });


    return(
      <>
        {this.state.loaded &&
          <>
            {component.state.result.map(function(item, i){
              //console.log(item)
              return (
                <div key={i}>
                <h5>{moment(item[0]).format("dddd, DD MMMM YYYY")}</h5>
                <MDBTable responsive hover striped>
                  <thead>
                    <tr>
                      <th className='th-lg'>
                        Behandelt von
                      </th>
                      <th className='th-lg'>
                        Behandelt am Standort
                      </th>
                      <th>Parameter</th>
                      <th>Bemerkung</th>
                    </tr>
                  </thead>
                  <tbody>
                    {component.state.loaded && (
                      item[1].map(function(row, j){
                        //console.log(row)
                        return (
                          <SessionResultRowPdf result={row}  key={'session-result-view-' + row.nid + row.changed} searchComponent={component} />
                        )
                      })
                    )}

                  </tbody>
                </MDBTable>
              </div>
              )
            })}
          </>
        }
      </>
    )
  }
}

export default SessionPdfList;
