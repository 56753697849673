import React from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import userActions from '../actions/userActions';

class AutoLogout extends React.Component {
  constructor(props) {
    super(props);

    this.state = { logginStatus: true };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];


    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.logoutTimeout = setTimeout(this.logout, 7200 * 1000);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  logout() {
    this.props.logout();
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }
  render() {
    return (
      <></>
    );
  }
}

function mapState(state) {
    const { authenticationReducer } = state;
    const { user } = authenticationReducer;
    return { user };
}

const actionCreators = {
  logout: userActions.logout
}


const connectedApp = connect(mapState, actionCreators)(AutoLogout);
export { connectedApp as AutoLogout };
