import React, { Component } from 'react';
import { connect } from 'react-redux';
import userActions from '../actions/userActions';


import {
  MDBIcon,
  MDBAvatar,
  MDBPopover,
  MDBBtn,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavLink
} from 'mdbreact';

class TopNavigation extends Component {
  constructor(props) {
    super(props);

    var currentLanguage = localStorage.getItem('lang');

    this.state = {
      collapse: false,
      user: this.props.user,
      languageOptions: [
        {
          text: "DE",
          value: "de",
          checked: currentLanguage === 'de' ? true :false
        },
        {
          text: "EN",
          value: "en",
          checked: currentLanguage === 'en' ? true :false
        }
      ]
    };

    this._onHandleToggle = this._onHandleToggle.bind(this);
    this._onLogout = this._onLogout.bind(this);
    this._onLanguageChange = this._onLanguageChange.bind(this);
    this._onReload = this._onReload.bind(this);
  }



  _onHandleToggle() {
    this.props.onSideNavToggleClick();
  }

  _onLogout() {
    this.props.logout();
  }

  _onLanguageChange(value) {
    localStorage.setItem('lang', value[0]);
  }

  _onReload() {
    window.location.reload(false);
  }

  render() {
    return (
      <section id="top-bar">

        <h1>{ this.props.routeName }</h1>

        <div id="menu-toggle"
            onClick={this._onHandleToggle}
            key='sideNavToggleA'
            style={{
              lineHeight: '32px',
              marginleft: '1em',
              verticalAlign: 'middle',
              cursor: 'pointer'
            }}
          >
          <MDBIcon icon='bars' color='white' size='lg' />
        </div>

        {this.state.user &&
          <div className="avatar">
              {/*
              <MDBAvatar
                  tag="img"
                  src={this.state.user.field_image[0].url}
                  className="rounded-circle img-fluid"
                  alt="Sample avatar"
                />*/}
                <MDBIcon icon="user-circle" />
                <span>{this.state.user.field_firstname[0].value} {this.state.user.field_lastname[0].value}</span>
                <MDBBtn flat onClick={this._onReload} id="reload-btn"><MDBIcon icon="redo-alt" /></MDBBtn>
          </div>
        }





      </section>
    );
  }
}


function mapState(state) {
    const { authenticationReducer } = state;
    const { user, locations } = authenticationReducer;
    return { user, locations };
}

const actionCreators = {
  logout: userActions.logout
}

const connectedTopNavigation= connect(mapState, actionCreators)((TopNavigation));
export { connectedTopNavigation as TopNavigation };
