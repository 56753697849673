import React, { Component } from 'react';
import axios from "axios";
import config from '../../../../config';



class LocationActions extends Component {

  constructor(props) {
    super(props);
    /** states **/
    this.state = {
    }

    /** binding methods **/
    this._get = this._get.bind(this);
    this._post = this._post.bind(this);
    this._patch = this._patch.bind(this);
    this._put = this._put.bind(this);
    this._delete = this._delete.bind(this);
  }

  componentDidMount(){
  }


  static _get(id) {
    return axios.get(`${config.backend_base}rest/location_api/${id}?_format=json`,{
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
      return response;
    })
  }

  static _post(values) {
    return axios.post(`${config.backend_base}rest/location_api/create?_format=json`,values,{
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
      return response;
    })
  }


  static _put(values) {
    return axios.put(`${config.backend_base}rest/location_api/${values.nid}?_format=json`,values,{
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
      return response;
    })
  }

  static _patch(values) {
    let nid = values.nid;
    values = JSON.stringify(values);
    return axios.patch(`${config.backend_base}rest/location_api/${nid}?_format=json`,values,{
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
      return response;
    })
  }

  static _delete(nid) {
    return axios.delete(`${config.backend_base}rest/location_api/${nid}?_format=json`,{
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
      return response;
    })
  }


  static _uploadImage(values) {
    return axios.post(`${config.backend_base}file/upload/node/location/field_image`,values,{
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': 'file; filename="testfile.png"'
        }
      }
    ).then(response => {
      return response;
    })
  }


  render() {
    return (
      <>
      </>
    );
  }
}

export default LocationActions;
