import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar
} from 'mdbreact';

import Image from "../image/Image";

import NumberFormat from 'react-number-format';


import CustomerActions from "./actions/CustomerActions";
import CustomerForm from "./CustomerForm";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";



class CustomerResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: false
    }

    this._onCustomerSelect = this._onCustomerSelect.bind(this);
  }

  _onCustomerSelect(e) {


    if(this.props.form.state.field_selected_recommendation_customer == this.props.result.nid) {
      this.props.form.setState({
        field_selected_recommendation_customer:null
      });
    } else {
      this.props.form.setState({
        field_selected_recommendation_customer: this.props.result.nid
      });
    }



    console.log(this.props.form);
  }

  render() {
    return (
      <tr className="customer-item" key={'customer-' + this.props.result.nid + this.props.result.changed}>

        <td>{(this.props.result.node.field_customer_number[0] ? this.props.result.node.field_customer_number[0].value : '')}</td>
        <td>{(this.props.result.node.field_firstname[0] ? this.props.result.node.field_firstname[0].value : '')}</td>
        <td>{(this.props.result.node.field_lastname[0] ? this.props.result.node.field_lastname[0].value : '')}</td>
        <td>{(this.props.result.node.field_city[0] ? this.props.result.node.field_city[0].value : '')}</td>
        <td>
          {this.props.form.state.field_selected_recommendation_customer == this.props.result.nid &&
            <MDBBtn color="green" onClick={this._onCustomerSelect}><MDBIcon icon="check-circle mr-2" /> ausgewählt</MDBBtn>
          }

          {this.props.form.state.field_selected_recommendation_customer != this.props.result.nid &&
            <MDBBtn onClick={this._onCustomerSelect}><MDBIcon far icon="check-circle mr-2" /> auswählen</MDBBtn>
          }

        </td>
      </tr>
    );
  }
}

export default CustomerResultRow;
