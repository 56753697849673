import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';
import PermissionHelper from "../../../helpers/PermissionHelper";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class TaskFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      taskFilter: null,
      statusOptions: [
        {
          text: "Offen",
          value: "0"
        },
        {
          text: "Erledigt",
          value: "1"
        }
      ]
    }

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
  }

  componentDidMount() {
    //console.log(this.props);
    /*
    var TaskFilter = JSON.parse(localStorage.getItem('TaskFilter'));
    if(!TaskFilter) {
      var TaskFilter = this.props.searchComponent.state;
      localStorage.setItem('TaskFilter', JSON.stringify(TaskFilter));
    }

    this.props.searchComponent.setState(TaskFilter);*/
  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var TaskFilter = this.props.searchComponent.state;
        //localStorage.setItem('TaskFilter', JSON.stringify(TaskFilter));
        this.props.searchComponent.loadTaskCollection();
    });

  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var TaskFilter = this.props.searchComponent.state;
        //localStorage.setItem('TaskFilter', JSON.stringify(TaskFilter));
        this.props.searchComponent.loadTaskCollection();
    });
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var TaskFilter = this.props.searchComponent.state;
        //localStorage.setItem('TaskFilter', JSON.stringify(TaskFilter));
        this.props.searchComponent.loadTaskCollection();
    });
  }

  _onInputChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var TaskFilter = this.props.searchComponent.state;
        //localStorage.setItem('TaskFilter', JSON.stringify(TaskFilter));
        this.props.searchComponent.loadTaskCollection();
    });
  }

  render() {
    let component = this;
    return (
      <div className="filter-card">




        <MDBRow>
          {PermissionHelper._hasPermission('ignore task') === true &&
            <MDBCol md="3" xs="12">
                <MDBSelect
                    options={this.props.searchComponent.state.employeeFilter}
                    selected="Bitte auswählen"
                    label="Mitarbeiter"
                    id="field_employee"
                    name="field_employee"
                    getValue={(e) => {
                      this._onSelectChange({
                        target: {
                          name: 'field_employee',
                          value: e,
                        },
                      });
                    }}
                />
            </MDBCol>
          }

        

          <MDBCol md="3" xs="12">
            <MDBSelect
                options={this.state.statusOptions}
                selected="Bitte auswählen"
                label="Status"
                id="field_status"
                name="field_status"
                getValue={(e) => {
                  this._onSelectChange({
                    target: {
                      name: 'field_status',
                      value: e,
                    },
                  });
                }}
            />
          </MDBCol>




        </MDBRow>
        <hr />


      </div>
    )
  }

}

export default TaskFilter;
