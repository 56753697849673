import { userConstants } from '../constants/userConstants';

let user = JSON.parse(localStorage.getItem('user'));
let locations = JSON.parse(localStorage.getItem('locations'));
const initialState = user && locations ? { loggedIn: true, user, error: false, locations  } : { error: false, loggedIn: false, locations:[], user:false };

export function authenticationReducer(state = initialState, action) {
  //console.log(state);
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      //console.log(action);
      return {
        error: false,
        loggedIn: false,
        loggingIn: true,
        user: action.user,
        locations: []
      };
    case userConstants.LOGIN_SUCCESS:
      //console.log(action);
      return {
        error: false,
        loggedIn: true,
        loggingIn: false,
        user: action.response.user,
        locations: action.response.locations,
      };
    case userConstants.LOGIN_FAILURE:
    return {
      loggedIn: false,
      loggingIn: false,
      error: action.error,
      user: false,
      locations: []
    };
    case userConstants.LOGOUT:
      state = undefined;
      return {};
    default:
      return state
  }
}
