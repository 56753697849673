import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBRow,
  MDBCol
} from 'mdbreact';

import NewsSearch from "../news/NewsSearch";

import TaskSearch from "../task/TaskSearch";


/** import styles **/
import '../../../assets/css/sections/dashboard.css';



class Dashboard extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }




  render() {
    return (
      <section id="dashboard">

        <MDBCard className="card-menu">
          <MDBCardBody>
            <MDBRow>
              <MDBCol>
                <MDBLink to='/customer'>
                  <MDBIcon icon="users" />
                  Kunden
                </MDBLink>
              </MDBCol>
              <MDBCol>
                <MDBLink to='/employee'>
                  <MDBIcon far icon="address-card" />
                  Mitarbeiter
                </MDBLink>
              </MDBCol>
              <MDBCol>
                <MDBLink to='/location'>
                  <MDBIcon icon="globe" />
                  Standorte
                </MDBLink>
              </MDBCol>
              <MDBCol>
                <MDBLink to='/news'>
                  <MDBIcon icon="newspaper" />
                  News
                </MDBLink>
              </MDBCol>
            </MDBRow>

          </MDBCardBody>
        </MDBCard>

        <MDBCard className="card-task">
          <MDBCardBody>
            <MDBCardTitle><MDBIcon icon="clipboard-check mr-2" /> Aufgaben</MDBCardTitle>
            <MDBCardText tag="div">
              <TaskSearch />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>


        <MDBCard className="card-locations">
          <MDBCardBody>
            <MDBCardTitle><MDBIcon icon="newspaper mr-2" /> News</MDBCardTitle>
            <MDBCardText tag="div">
              <NewsSearch />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>



      </section>
    );
  }
}

export default Dashboard;
