import React, {
  Component
} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBTimePicker,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSelect
} from "mdbreact";

import TaskActions from "./actions/TaskActions";
import PermissionHelper from "../../../helpers/PermissionHelper";


import moment from 'moment';
import 'moment/locale/de';


class TaskForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      modal: false,
      body: '',
      field_status: 0,
      field_date: new Date(),
      field_time: "12:00",
      field_location: (this.props.route == '/taskteam') ? [localStorage.getItem('selectedLocation')] : null,
      loaded: true,
      locationsFilter: this.props.locationsFilter,
    }

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onGetDatePickerValue = this._onGetDatePickerValue.bind(this);
    this._onGetTimePickerValue = this._onGetTimePickerValue.bind(this);
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onGetDatePickerValue = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      'field_date': value,
    }))
  }

  _onGetTimePickerValue(value) {
    //console.log(value);
    this.setState(prevState => ({
      'field_time': value,
    }))
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    this.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))

    let values = {
      field_date: this.state.field_date,
      field_time: this.state.field_time,
      field_location: this.state.field_location,
      body: this.state.body
    }
    //console.log(values);
    TaskActions._post(values)
      .then(response => {

        component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
        }))

        component.props.searchComponent.loadTaskCollection();
        component._onToggleModal();
        component.setState(prevState => ({
          loaded: true,
          body: ''
        }))
      });
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }


  render() {


    var locationTaskPermission = true;


    if(!PermissionHelper._hasPermission('config location task') && this.props.route == '/taskteam') {
      locationTaskPermission = false;
    }

    var component = this;
    return (
      <>
      {!this.state.entity &&
        <>
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neue Aufgabe erfassen</MDBBtn>
          <hr />
        </>
      }


      <MDBModal size="lg" isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Ausgabenverwaltung</MDBModalHeader>
        <MDBModalBody>
          <form id="task-add-form" onSubmit={this._onSubmit}>
            <MDBRow>
              <MDBCol size="12">


                <MDBRow>
                  <MDBCol size="6">
                    <MDBDatePicker id="field_date" className="form-datepicker" label="Datum" name="field_date" value={this.state.field_date} required cancelLabel="Abbrechen" locale={moment.locale('de')} getValue={this._onGetDatePickerValue} />
                  </MDBCol>
                  <MDBCol size="6">

                    <div className="md-form form-group">
                      <input id="field_time" name="field_time" value={this.state.field_time} onChange={this._onInputChange} className="form-control" type="time" />
                      <label className="active" data-error="" data-success="" id="" aria-labelledby="">Zeit</label>
                    </div>


                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size="12">
                <MDBInput type="textarea" label="Bemerkung" rows="3" name="body" value={this.state.body} onChange={this._onInputChange} required />
              </MDBCol>
            </MDBRow>


            <MDBBtn color="primary" disabled={this.state.hasSubmitted ? true : false}  type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
          </form>
        </MDBModalBody>
      </MDBModal>



      </>
    );
  }
}

export default TaskForm;
