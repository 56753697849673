import axios from "axios";
import config from '../config';
import { history } from '../helpers/history';
import { Component } from 'react';



class userService extends Component {
  constructor(props) {
    super(props);
    /** binding methods **/
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }


  static login(username, password) {
    let postData = JSON.stringify({
      'name': username,
      'pass': password
    });
    return axios.post(`${config.backend_base}user/login?_format=json`, postData, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(function(loginResponse) {
        console.log(loginResponse.data);
        if (loginResponse.status > 200) {
          return Promise.reject(loginResponse.statusText);
        }
        return loginResponse.data;
    }).then(function(loginResponse) {
          loginResponse.user = loginResponse.current_user;
          loginResponse.user.logout_token = loginResponse.logout_token;
          loginResponse.user.access_token = loginResponse.access_token;
          delete(loginResponse.current_user);
          localStorage.setItem('user', JSON.stringify(loginResponse.user));
          //console.log(response);

          return axios.get(`${config.backend_base}rest/employee_api/${loginResponse.user.uid}?_format=json`, {
            headers: {
              'Content-Type': 'application/json',
            }
          }).then(function(userDetailResponse) {
            if (userDetailResponse.status > 200) {
              return Promise.reject(userDetailResponse.statusText);
            }
            return userDetailResponse.data;
          }).then(function(userDetailResponse) {
            var user = userDetailResponse.entity;
            user.logout_token = loginResponse.logout_token;
            user.access_token = loginResponse.access_token;
            //user.nodejs_auth_token = loginResponse.nodejs_auth_token;
            localStorage.setItem('user', JSON.stringify(user));
            var locations = userDetailResponse.locations;
            //console.log(user);
            //console.log(locations);

            return axios.get(`${config.backend_base}rest/permission_api/permission/${user.uid[0].value}?_format=json`, {
              headers: {
                'Content-Type': 'application/json',
              }
            }).then(function(permissionResponse) {
              if (permissionResponse.status > 200) {
                return Promise.reject(permissionResponse.statusText);
              }
              return permissionResponse.data;
            }).then(function(permissionResponse) {
              localStorage.setItem('locations', JSON.stringify(locations));
              localStorage.setItem('selectedLocation', locations[0].nid[0].value);

              user.permissions = permissionResponse;
              localStorage.setItem('user', JSON.stringify(user));
              var response = {
                locations: locations,
                user: user
              };
              return response;
            })
          });
    }).catch(function(error) {
      return Promise.reject(error);
    })
  }

  static logout() {
    var user = JSON.parse(localStorage.getItem('user'));
    if(user) {
      return axios.get(`${config.backend_base}user/logout?token=${user.logout_token}&_format=json`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(function(response) {
        // remove user from local storage to log user out
        //alert('logout 1');
        localStorage.removeItem('user', null);
        localStorage.removeItem('locations', null);
        localStorage.removeItem('selectedLocation', null);
        localStorage.removeItem('customerFilter', null);
        return response;
      }).catch(function(error) {
        //alert('logout 2');
        // remove user from local storage to log user out
        localStorage.removeItem('user', null);
        localStorage.removeItem('locations', null);
        localStorage.removeItem('selectedLocation', null);
        localStorage.removeItem('customerFilter', null);
        return Promise.reject(error);
      })
    } else {
        //alert('logout 3');
        // remove user from local storage to log user out
        //localStorage.removeItem('user', null);
        //localStorage.removeItem('locations', null);
        //localStorage.removeItem('selectedLocation', null);
        return Promise.reject('Bitte einloggen');
    }
  }

  static checkSession(user) {
    var user = JSON.parse(localStorage.getItem('user'));
    if(typeof user == 'undefined' || !localStorage.getItem('user')) {
        // the variable is defined
        return Promise.reject('Bitte einloggen');
    }
    //console.log(user.access_token);
    return axios.get(`${config.backend_base}jwt/token?_format=json`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.access_token
      }
    }).then(function(response) {
      return response.data;
    }).then(function(response) {
        user.access_token = response.token;
        var locations = JSON.parse(localStorage.getItem('locations'));
        localStorage.setItem('user', JSON.stringify(user));
        var response = {
          locations: locations,
          user: user
        };
        return response;
    }).catch(function(error) {
      return Promise.reject(error);
    })
  }
}

export default userService;
