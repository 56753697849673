import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';

import { Editor } from '@tinymce/tinymce-react';

import LocationActions from "./actions/LocationActions";
import Image from "../image/Image";

import moment from 'moment';
import 'moment/locale/de';

class LocationForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entity: this.props.entity,
      modal: false,
      nid: false,
      title: '',
      body: '',
      field_image: false,
      field_city: '',
      field_street: '',
      field_zip: '',
      field_email: '',
      field_phone: '',
      field_opening: [
        [''],
        [''],
        [''],
        [''],
        [''],
        [''],
        [''],
      ],
      loaded: true
    }

    this.locationFilterRef = React.createRef();
    this.locationCategoryFilterRef = React.createRef();
    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
  }

  componentDidMount() {
    if(this.props.entity) {
      this.setState(prevState => ({
        nid: this.props.entity.nid[0].value,
        title: this.props.entity.title[0].value,
        field_city: this.props.entity.field_city[0].value,
        field_street: this.props.entity.field_street[0].value,
        field_zip: this.props.entity.field_zip[0].value,
        field_email: this.props.entity.field_email[0].value,
        field_phone: this.props.entity.field_phone[0].value
      }))


      if(this.props.entity.field_opening[0]) {
        //console.log(this.props.entity.field_opening);
        this.setState(prevState => ({
          field_opening: [
            [this.props.entity.field_opening[0].value],
            [this.props.entity.field_opening[1].value],
            [this.props.entity.field_opening[2].value],
            [this.props.entity.field_opening[3].value],
            [this.props.entity.field_opening[4].value],
            [this.props.entity.field_opening[5].value],
          ],
        }))
      }
    }
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    this.setState(prevState => ({
      loaded: false,
    }))


    if(!this.state.nid) {
      if(!this.state.field_image) {
        alert('Bitte lade ein Bild hoch.');
        return;
      }
    }

    let values = {
      nid: this.state.nid,
      title: this.state.title,
      body: this.state.body,
      field_image: this.state.field_image,
      field_city: this.state.field_city,
      field_street: this.state.field_street,
      field_zip: this.state.field_zip,
      field_email: this.state.field_email,
      field_phone: this.state.field_phone,
      field_opening: this.state.field_opening
    }

    if(!this.state.nid) {
      LocationActions._post(values)
        .then(response => {
          component.props.searchComponent.loadLocationCollection();
          component._onToggleModal();
          component._onFormReset();
        });
    } else {
      LocationActions._patch(values)
        .then(response => {
          component.props.searchComponent.loadLocationCollection();
          component._onToggleModal();
        });
    }
  }

  _onInputChange(e) {

    if(e.target.name.includes('[')) {
      var fieldName = e.target.name.split('[');
      var name = fieldName[0];
      //console.log(name);

      var index = fieldName[1].split(']');
      index = index[0];
      //console.log(index);

      var val = this.state[name];
      //console.log(val);
      val[index] = e.target.value;


      this.setState({
        [name]: val,
      });

    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }



    //console.log(this.state);
  }

  _onChangeHandleFile = (file) => {
    var component = this;
    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         LocationActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);

    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.fileInputRef.current){
      this.fileInputRef.current.resetPreview();
    }

    this.setState(prevState => ({
      loaded: true,
      nid: false,
      title: '',
      body: '',
      field_image: false,
      field_city: '',
      field_street: '',
      field_zip: '',
      field_email: '',
      field_phone: '',
      field_opening: [
        [''],
        [''],
        [''],
        [''],
        [''],
        [''],
        [''],
      ],
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    let component = this;
    return (
      <>
      {!this.state.entity &&
        <>
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Standort erfassen</MDBBtn>
          <hr />
        </>
      }

      {this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


        <MDBModal size="lg" isOpen={this.state.modal} toggle={this._onToggleModal}>
          <MDBModalHeader toggle={this._onToggleModal}>Standortverwaltung</MDBModalHeader>
          <MDBModalBody>
            <form id="location-add-form" onSubmit={this._onSubmit}>
              <MDBRow>

                <MDBCol size="12">
                    <MDBFileupload
                        maxFileSize="16M"
                        ref={this.fileInputRef}
                        getValue={this._onChangeHandleFile}
                        />

                </MDBCol>
                <MDBCol size="12">

                  <MDBInput label="Titel" name="title" id="title" onChange={this._onInputChange} value={this.state.title} required />

                  <MDBRow>
                    <MDBCol>
                      <MDBInput label="Strasse" name="field_street" id="field_street" onChange={this._onInputChange} value={this.state.field_street} required />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput label="PLZ" name="field_zip" id="field_zip" onChange={this._onInputChange} value={this.state.field_zip} required />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput label="Ort" name="field_city" id="field_city" onChange={this._onInputChange} value={this.state.field_city} required />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput label="Telefon" name="field_phone" id="field_phone" onChange={this._onInputChange} value={this.state.field_phone} required />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput label="E-Mail" name="field_email" id="field_email" type="email" onChange={this._onInputChange} value={this.state.field_email} required />
                    </MDBCol>
                  </MDBRow>
                  <h5>Öffnungszeiten</h5>

                    <MDBRow>
                      <MDBCol md="4">
                        <MDBInput label="Montag" name="field_opening[0]" id="field_opening" onChange={this._onInputChange} value={this.state.field_opening[0]} required />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput label="Dienstag" name="field_opening[1]" id="field_opening" onChange={this._onInputChange} value={this.state.field_opening[1]} required />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput label="Mittwoch" name="field_opening[2]" id="field_opening" onChange={this._onInputChange} value={this.state.field_opening[2]} required />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput label="Donnerstag" name="field_opening[3]" id="field_opening" onChange={this._onInputChange} value={this.state.field_opening[3]} required />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput label="Freitag" name="field_opening[4]" id="field_opening" onChange={this._onInputChange} value={this.state.field_opening[4]} required />
                      </MDBCol>
                      <MDBCol md="4">
                        <MDBInput label="Samstag" name="field_opening[5]" id="field_opening" onChange={this._onInputChange} value={this.state.field_opening[5]} required />
                      </MDBCol>
                    </MDBRow>

                    <hr />

                      <MDBRow>
                        <MDBCol>
                          {!this.state.entity &&
                            <MDBBtn color="primary" className="mr-2" onClick={this._onFormReset}><MDBIcon icon="sync mr-2" /> Reset</MDBBtn>
                          }

                          {!this.state.field_image &&
                            <MDBBtn color="primary" type="submit" disabled><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                          }

                          {this.state.field_image &&
                            <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                          }
                        </MDBCol>
                      </MDBRow>


                </MDBCol>
              </MDBRow>
            </form>
          </MDBModalBody>
        </MDBModal>
      </>


    );
  }
}

export default LocationForm;
