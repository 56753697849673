import React, { Component } from 'react';
import {
  MDBSpinner,
  MDBLink,
  MDBIcon,
  MDBCard,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBAnimation,
  MDBNavLink,
  MDBBtn,
  MDBCardText,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBNav,
  MDBNavItem,
  MDBTabContent,
  MDBTabPane
} from 'mdbreact';

import TreatmentActions from './actions/TreatmentActions';

import PermissionHelper from "../../../helpers/PermissionHelper";

import SessionSearch from '../session/SessionSearch';

import CheckupSearch from "../checkup/CheckupSearch";

import moment from 'moment';
import 'moment/locale/de';

import TreatmentPDF from './TreatmentPDF';
import ReactToPrint from 'react-to-print';




/** import styles **/
import '../../../assets/css/sections/treatment.css';


class TreatmentDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeItem: "1",
      entity: null,
      owner: null,
      customer: null,
      treatmentType: null,
      parameters: null,
      loaded: false,
      channel: '',
      sessionCollection: null,
      anamnesisModal: false,
    };


    this.treatmendPdfRef = React.createRef();
    this.sessionRef = React.createRef();

    this._onTabToggle = this._onTabToggle.bind(this);
    this._loadTreatmentDetails = this._loadTreatmentDetails.bind(this);
    this._onToggleAnamnesis = this._onToggleAnamnesis.bind(this);
  }

  componentDidMount() {
    this._loadTreatmentDetails();
  }

  _loadTreatmentDetails() {
    this.setState({
      loaded: false
    });

    var component = this;
    TreatmentActions._get(this.props.match.params.id).then(response => {
      //console.log(response);
      this.setState({
        entity: response.data.entity,
        owner: response.data.owner,
        customer: response.data.customer,
        image: response.data.image,
        stencil: response.data.stencil,
        treatmentType: response.data.treatmentType,
        agreementDetails: response.data.agreementDetails,
        agreements: response.data.agreements,
        channel: response.data.channel,
        loaded: true
      });
    });
  }


  _onToggleAnamnesis = () => {
    this.setState({
      anamnesisModal: !this.state.anamnesisModal
    });
  }

  _onTabToggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };


  render() {

    var component = this;
    let renderAnamnesisParams;
    if(this.state.entity) {
      var paramValues = Object.values(JSON.parse(this.state.entity.field_anamnesis[0].value));
      renderAnamnesisParams = paramValues.map((item, i) => {
        var values = Object.values(item);
        var keys = Object.keys(item);
        return (<div className="item" key={i} className="question"><strong>{keys[0]}</strong> {values[0]}</div>);
      });
    }

    let renderAgreementParams;
    if(this.state.entity) {
      var paramValues = Object.values(JSON.parse(this.state.entity.field_agreement[0].value));
      renderAgreementParams = paramValues.map((item, i) => {
        var values = Object.values(item);
        var keys = Object.keys(item);
        return (<div className="item" key={i}><strong>{keys[0]}:</strong> {values[0]}</div>);
      });
    }



    return (


      <>
        <section id="treatment-detail" {...(this.state.loaded && this.state.sessionCollection ? {key: this.state.sessionCollection.length} : {})}>
            {!this.state.loaded &&
              <div className="text-center">
                <MDBSpinner red />
              </div>
            }

            {this.state.loaded &&


              <MDBCard>
                <MDBCardBody>
                  <MDBCardTitle>
                    <MDBRow>
                      <MDBCol size="6" className="">
                        {this.state.treatmentType} (#{this.state.entity.field_treatment_number[0].value})
                      </MDBCol>
                      <MDBCol size="6" className="text-right">

                        <div className="action-bar">
                          <MDBNavLink to={"/customer/" + this.state.entity.field_customer[0].target_id}>
                            <MDBBtn size="sm" color="info">
                              <MDBIcon icon="chevron-left" />
                            </MDBBtn>
                          </MDBNavLink>
                        </div>


                      </MDBCol>
                    </MDBRow>
                  </MDBCardTitle>
                  <MDBCardText tag="div">
                    <div className="row">
                      <div className="col-md-4">
                        <h3>Behandlungsdetails</h3>
                          <div className="item">
                            <div className="label">Behandelter Kunde</div>
                            <div className="value">{this.state.customer.field_firstname[0].value} {this.state.customer.field_lastname[0].value}</div>
                          </div>
                          <div className="item">
                            <div className="label">Behandlung erfasst von</div>
                            <div className="value">{this.state.owner.field_firstname[0].value} {this.state.owner.field_lastname[0].value}</div>
                          </div>
                          <div className="item">
                            <div className="label">Behandlungsstart</div>
                            <div className="value">{moment(new Date(this.state.entity.created[0].value)).format("DD.MM.YYYY HH:mm")}</div>
                          </div>
                          {this.state.lastUpdate &&
                            <div className="item">
                              <div className="label">Behandlungsupdate</div>
                              <div className="value">{moment.unix(this.state.lastUpdate).format("DD.MM.YYYY HH:mm")}</div>
                            </div>
                          }


                      </div>
                      <div className="col-md-8">
                        <h3>Details</h3>

                          {this.state.entity &&
                            <>
                              {renderAgreementParams}
                            </>
                          }
                          <div className="item"><strong>Channel:</strong> {this.state.channel}</div>
                            {this.state.entity.field_channel_other[0] && this.state.entity.field_channel[0].target_id == 29 &&
                              <>
                                <div className="item"><strong>Kundenreferenz:</strong> {this.state.entity.field_channel_other[0].value}</div>
                              </>
                            }
                            {this.state.entity.field_channel_other[0] && this.state.entity.field_channel[0].target_id == 32 &&
                              <>
                                <div className="item"><strong>Andere:</strong> {this.state.entity.field_channel_other[0].value}</div>
                              </>
                            }


                          <div className="anamnesis-modal d-inline">
                            {this.state.entity && this.state.image &&
                              <>

                              <MDBBtn onClick={this._onToggleAnamnesis} className="mr-2"><MDBIcon fab icon="readme mr-2" /> Anamnese öffnen</MDBBtn>
                              <MDBModal size="lg" isOpen={this.state.anamnesisModal} toggle={this._onToggleAnamnesis}>
                                <MDBModalHeader toggle={this._onToggleAnamnesis}>Anamnese</MDBModalHeader>
                                <MDBModalBody>
                                  {renderAnamnesisParams}
                                </MDBModalBody>
                              </MDBModal>
                              </>
                            }
                          </div>


                          {this.state.entity && this.state.image &&
                            <>
                              <ReactToPrint
                                trigger={() => <MDBBtn href="#"><MDBIcon icon="print mr-2" /> Drucken</MDBBtn>}
                                content={() => this.treatmendPdfRef}
                              />
                              <>
                                <div className="hide">
                                  <TreatmentPDF nid={this.props.match.params.id} ref={el => (this.treatmendPdfRef = el)} sessions={this.sessionRef} treatment={this.state.entity} entity={this.state.entity} customer={this.state.customer} image={this.state.image} anamnesisDetails={Object.values(JSON.parse(this.state.entity.field_anamnesis[0].value))} agreementDetails={this.state.agreementDetails} />
                                </div>
                              </>

                            </>
                          }

                      </div>
                    </div>


                    <div className="treatment-tabs">
                      <MDBNav tabs>
                        <MDBNavItem>
                          <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this._onTabToggle("1")} role="tab">Sitzungsprotokoll</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                          <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this._onTabToggle("2")} role="tab">CheckUp-Termine</MDBNavLink>
                        </MDBNavItem>
                      </MDBNav>
                      <MDBTabContent className="card-no" activeItem={this.state.activeItem}>
                        <MDBTabPane tabId="1" role="tabpanel">
                          <hr />
                          <SessionSearch stencil={component.state.stencil} treatmentDetail={component} nid={this.props.match.params.id} treatment={this.state.entity} />
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                          <hr />
                          <CheckupSearch nid={this.props.match.params.id} treatment={this.state.entity} />
                        </MDBTabPane>
                      </MDBTabContent>
                    </div>



                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>




            }

          </section>
        </>
    );
  }
}

//export default Customers;
export default TreatmentDetail;
