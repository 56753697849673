import React, { Component } from 'react';

import {
  MDBTable,
  MDBTableHead,
  MDBTableBody
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';

import SessionResultRow from "../session/SessionResultRow";
import SessionPdfList from '../session/SessionPdfList';


class TreatmentPDF extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    let component = this;



    //console.log(component.props.sessions.current);

    if(component.props.sessions.current && component.props.sessions.current.state.result) {
      var paramValues = Object.values(JSON.parse(component.props.sessions.current.state.result[0][1][0].node.field_parameters[0].value));
    } else {
      var paramValues = [];
    }

    const renderParams = paramValues.map((item, i) => {
      var values = Object.values(item);
      var keys = Object.keys(item);
      //console.log(keys);
      return (<th key={i}>{keys[0]}</th>);
    });

    //console.log(this.props);

    return (
      <section id="treatment-pdf">
        <div className="page-header">
          <br/>
        </div>

        <div className="page-footer">
        </div>

        <table>

            <thead>
              <tr>
                <th>
                  <div className="page-header-space"></div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div className="page">

                    <h1>Kundenvereinbarung</h1>

                    <div className="seperator">zwischen</div>

                    <table className="agreement-table">
                      <tbody>
                      <tr>
                        <td>
                          Veyu
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>und</td>
                        <td>und</td>
                      </tr>
                      <tr>
                        <td>Name:</td>
                        <td>
                          {this.props.customer.field_lastname[0] &&
                            this.props.customer.field_lastname[0].value
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Vorname:</td>
                        <td>
                          {this.props.customer.field_firstname[0] &&
                            this.props.customer.field_firstname[0].value
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Adresse:</td>
                        <td>
                          {this.props.customer.field_street[0] &&
                            this.props.customer.field_street[0].value
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>PLZ / Ort</td>
                        <td>
                          {this.props.customer.field_zip[0] &&
                            this.props.customer.field_zip[0].value + ' '
                          }
                          {this.props.customer.field_city[0] &&
                            this.props.customer.field_city[0].value
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Tel. / Mobil</td>
                        <td>
                          {this.props.customer.field_phone[0] &&
                            this.props.customer.field_phone[0].value
                          }
                          {this.props.customer.field_mobile[0] &&
                            this.props.customer.field_mobile[0].value
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>E-Mail Adresse</td>
                        <td>
                          {this.props.customer.field_email[0] &&
                            this.props.customer.field_email[0].value
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>(nachfolgend Kunde genannt)</td>
                        <td></td>
                      </tr>
                      </tbody>
                    </table>

                    <hr />

                    <div className="agreement-details-bar">
                      {this.props.agreementDetails.map(function(details, i){
                        return (
                            <div className="details-bar" key={i}>{details.value}</div>
                        );
                      })}
                    </div>

                    <hr />

                    <div className="sign-bar">
                      <table>
                      <tbody>
                        <tr>
                          <td>
                            <label>Datum</label>
                            <div>
                              {moment(new Date(this.props.entity.created[0].value)).format("DD.MM.YYYY")}
                            </div>
                          </td>
                          <td>
                            <label>Unterschrift</label>
                            <img src={this.props.image} />
                          </td>
                        </tr>
                      </tbody>
                      </table>

                    </div>



                  </div>
                  <div className="page">
                    <h1>Anamnese</h1>
                      <div className="agreement-details-bar">
                        {this.props.anamnesisDetails.map((item, i) => {
                          var values = Object.values(item);
                          var keys = Object.keys(item);
                          return (<div className="details-bar quetion" key={i} className="question"><strong>{keys[0]}</strong> {values[0]}</div>);
                        })}
                      </div>

                  </div>
                  <div className="page">
                    <h1>Sitzungsprotokoll</h1>
                    <div className="sessions-bar">
                      <SessionPdfList nid={this.props.nid} treatment={this.props.treatment} />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td>
                  <div className="page-footer-space"></div>
                </td>
              </tr>
            </tfoot>

        </table>
      </section>
    );
  }
}


//export default Customers;
export default TreatmentPDF;
