import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import CommentActions from "./actions/CommentActions";

import Image from "../image/Image";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class CommentResultRow extends React.Component {

  constructor(props) {
    super(props);

    this._onDelete = this._onDelete.bind(this);
  }



  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {

      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      CommentActions._delete(this.props.result.cid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadCommentCollection();
        });
    }
  }


  render() {
    return (
      <div className={"comment " + (this.props.result.field_important == '1' ? 'important' : '')}>
        {PermissionHelper._hasPermission('restful delete Comment') === true &&
        <div className="action-bar">

            <>
              <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
            </>

        </div>
        }

          <div className="content">
            <h3>
              {this.props.result.firstname}<br />
            {this.props.result.lastname}
              <span>
                <time> {moment.unix(this.props.result.created, "YYYYMMDD").fromNow()}</time>
              </span>
            </h3>
            <div>
              <h6>{this.props.result.subject}</h6>
              <p>
                {this.props.result.comment_body}

              </p>
            </div>
          </div>
        </div>
    );
  }
}

export default CommentResultRow;
