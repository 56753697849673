import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar
} from 'mdbreact';

import Image from "../image/Image";

import NumberFormat from 'react-number-format';


import CustomerActions from "./actions/CustomerActions";
import CustomerForm from "./CustomerForm";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";



class CustomerResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      CustomerActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadCustomerCollection();
        });
    }
  }

  render() {
    return (
      <tr className="customer-item" key={'customer-' + this.props.result.nid + this.props.result.changed}>

        <td>{(this.props.result.node.field_customer_number[0] ? this.props.result.node.field_customer_number[0].value : '')}</td>
        <td>{(this.props.result.node.field_firstname[0] ? this.props.result.node.field_firstname[0].value : '')}</td>
        <td>{(this.props.result.node.field_lastname[0] ? this.props.result.node.field_lastname[0].value : '')}</td>
        <td>
          {(this.props.result.node.field_mobile[0] ? <NumberFormat displayType={'text'} format="+## ## ### ## ##" mask="_" value={this.props.result.node.field_mobile[0].value}/> : '')}
        </td>
        <td>{(this.props.result.node.field_city[0] ? this.props.result.node.field_city[0].value : '')}</td>
        <td>

          <div className="action-bar">

            <MDBPopover
              placement="top"
              popover
              clickable
              id={'treatment-popover-' + this.props.result.nid}
            >
          <MDBBtn><MDBIcon icon="user-md" /></MDBBtn>
            <div>
              <MDBPopoverHeader>Behandlungen</MDBPopoverHeader>
              <MDBPopoverBody>
                <ul className="treatment-list">
                  {
                    Object.values(this.props.result.treatments).map(function(item, i){
                      //console.log(item);
                      return <li key={i}><span>{item}</span></li>
                    })
                  }
                </ul>
              </MDBPopoverBody>
            </div>
          </MDBPopover>

            <MDBPopover
              placement="top"
              popover
              clickable
              id={'location-popover-' + this.props.result.nid}
            >
            <MDBBtn><MDBIcon icon="globe" /></MDBBtn>
            <div>
              <MDBPopoverHeader>Standorte</MDBPopoverHeader>
              <MDBPopoverBody>
                <ul className="location-list">
                  {
                    this.props.result.locations.map(function(item, i){
                      //console.log(item);
                      return <li key={i}><span>{item.title[0].value}</span></li>
                    })
                  }
                </ul>
              </MDBPopoverBody>
            </div>
          </MDBPopover>

            <MDBBtn color="info">
              <MDBLink to={'/customer/' + this.props.result.nid} link>
                <MDBIcon icon="info-circle" />
              </MDBLink>
            </MDBBtn>
            {PermissionHelper._hasPermission('restful delete Customer') === true &&
              <>
                <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
              </>
            }
            {PermissionHelper._hasPermission('edit any customer content') === true &&
              <>
                <CustomerForm key={'customer-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} locations={this.props.result.locations} genders={this.props.searchComponent.state.genders} searchComponent={this.props.searchComponent} channelOptions={this.props.searchComponent.state.channelOptions} genderFilters={this.props.searchComponent.state.genderFilters} locationsFilter={this.props.searchComponent.state.locations} />
              </>
            }
          </div>



        </td>
      </tr>
    );
  }
}

export default CustomerResultRow;
