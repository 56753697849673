import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBAnimation,
  MDBNavLink,
  MDBIcon,
  MDBSpinner,
  MDBAlert,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';

import TreatmentActions from './actions/TreatmentActions';

import TreatmentFormAnamnesisItem from './TreatmentFormAnamnesisItem';

import { history } from '../../../helpers/history';

import SignaturePad from 'react-signature-canvas';

import Viewer, { Worker, RenderViewerProps, ScrollMode, SpecialZoomLevel, SelectionMode } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';



class TreatmentForm extends Component {

  constructor(props) {
    super(props);
    /** states **/
    this.state = {
      collapseID: "collapse2",
      entity: null,
      typeOptions: [],
      imageURL: null,
      image: null,
      modal: false,
      showAgreement: false,
      showAnamnesis: false,
      showSignPad: false,
      fields: {
        field_location: localStorage.getItem('selectedLocation'),
        field_customer: this.props.nid,
        field_treatment_type: null,
        field_channel: null,
        field_channel_other: null,
        field_image: null
      }
    }


    /** refs **/
    this.sigCanvas = React.createRef();
    this.modalRef = React.createRef();

    /** binding methods **/
    this._onSubmit = this._onSubmit.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onToggleCollapse = this._onToggleCollapse.bind(this);
    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSignatureSave = this._onSignatureSave.bind(this);
    this._onSignatureClear = this._onSignatureClear.bind(this);
    this._onTypeChange = this._onTypeChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChannelChange = this._onChannelChange.bind(this);
  }

  componentDidMount() {
    var component = this;



    component.setState({
      typeOptions: this.props.searchComponent.state.treatmentTypes
    });

    component.setState({
      channelOptions: this.props.searchComponent.state.channelOptions
    });
    component.state.typeOptions.forEach(function(item, index) {
      if(item.value == component.state.fields.field_treatment_type) {
        item.checked = true;
      }
    });



  }

  _onToggleCollapse = collapseID => () => {
    console.log(this.modalRef.current.children);
    console.log(this.modalRef.current.children.length);
    console.log(this.modalRef.current.children['2']);
    //console.log(this.modalRef.current.children.index(3));
    this.modalRef.current.children['2'].scrollTo(0, 0);
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  _onToggleCollapse_(collapseID) {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  _onToggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSubmit(event) {
    event.preventDefault();
    let component = this;
    console.log(this.state);
    TreatmentActions._post(this.state.fields).then(response => {
      component._onToggleModal();
      component.props.searchComponent.loadTreatmentCollection();
    });
  }

  _onInputChange(e) {

    //console.log(e);
    var fields = this.state.fields;
    console.log(fields);
    var name = e.target.name;
    fields[name] = e.target.value;
    this.setState({
      fields: fields
    });
  }

  _onTypeChange(value) {
    let component = this;


    var fields = {
      field_location: localStorage.getItem('selectedLocation'),
      field_customer: this.props.nid,
      field_channel: this.state.fields.field_channel,
      field_channel_other: this.state.fields.field_channel_other,
      imageURL: null,
    }

    fields.field_treatment_type = value[0];

    component.setState({
      fields: fields,
      imageURL: null
    });


    this.state.typeOptions.map(function(item, n){

      if(item.value === value[0]) {
        //console.log(item.agreementDetails.length);

        if(item.agreementDetails.length > 0) {
          component.setState({
            showAgreement: true
          });
        } else {
          component.setState({
            showAgreement: false
          });
        }

        if(item.anamnesis.length > 0) {
          component.setState({
            showAnamnesis: true
          });
        } else {
          component.setState({
            showAnamnesis: false
          });
        }
      }
    })


  };

  _onChannelChange(value) {

    var fields = this.state.fields;
    fields.field_channel = value;

    this.setState({
      fields: fields
    });
  };

  _onCheckboxChange(e) {
    console.log(e.target.checked);
    this.setState({
      [e.target.name]: e.target.checked
    },() => {
        console.log(this.state);
    });
  }

  _onSignatureSave() {
    var component = this;
    this.setState({
      imageURL: this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    });

    var dataURI = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    var i = 0;
    for(i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    TreatmentActions._uploadImage(array).then(response => {

      var fields = component.state.fields;
      fields.field_image = response.data.fid[0].value;

      component.setState({
        fields: fields
      });
    });
    this.sigCanvas.current.clear();
  }

  _onSignatureClear() {
    if(this.sigCanvas.current) {
      this.sigCanvas.current.clear();
    }

    var fields = this.state.fields;
    fields.field_image = false;
    this.setState({
      imageURL: null,
      fields: fields
    });
  }


  render() {
    let component = this;
    return (
      <>
      <section id="customer-treatment-form" ref={this.modalRef}>
        <MDBBtn color="primary" onClick={this._onToggleModal}><MDBIcon icon="plus mr-2" /> Neue Behandlung starten</MDBBtn>
        <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal} size="lg">
          <MDBModalHeader toggle={this._onToggleModal}>Neue Behandlung</MDBModalHeader>
          <MDBModalBody>
            <form id="treatment-form" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol className="text-left">
                  <MDBSelect
                    selected="Bitte auswählen"
                    name="field_treatment_type"
                    options={this.state.typeOptions}
                    error='wrong'
                    success='right'
                    required
                    getValue={this._onTypeChange}
                    onChange={this._onTypeChange}
                    label="Behandlungsart"
                  />
                </MDBCol>
              </MDBRow>

              {component.state.fields.field_treatment_type && false &&
                <MDBRow>
                  <MDBCol className="text-left">
                    <MDBSelect
                      selected="Bitte auswählen"
                      name="field_channel"
                      options={this.state.channelOptions}
                      error='wrong'
                      success='right'
                      required
                      getValue={this._onChannelChange}
                      onChange={this._onChannelChange}
                      label="Channel"
                    />
                  </MDBCol>
                </MDBRow>
              }


              {component.state.fields.field_channel == '29' && false &&
                <MDBRow>
                  <MDBCol className="text-left">
                    <MDBInput id="field_channel_other" name="field_channel_other" value={component.state.fields.field_channel_other} onChange={component._onInputChange} label="Empfohlen von" required />
                  </MDBCol>
                </MDBRow>
              }

              {component.state.fields.field_channel == '32' && false &&
                <MDBRow>
                  <MDBCol className="text-left">
                    <MDBInput id="field_channel_other" name="field_channel_other" value={component.state.fields.field_channel_other} onChange={component._onInputChange} label="Andere" required />
                  </MDBCol>
                </MDBRow>
              }

              {component.state.fields.field_treatment_type &&
                <>
                  {component.state.showAnamnesis &&
                    <>
                    <MDBCollapseHeader onClick={this._onToggleCollapse("collapse2")}>
                      Fragebogen
                      <i className={ this.state.collapseID==="collapse2" ? "fa fa-angle-down" : "fa fa-angle-up" } />
                    </MDBCollapseHeader>
                    <MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
                      <MDBCardBody>
                        <div className="anamnesis-bar">
                          {this.state.typeOptions.map(function(item, n){
                            if(item.value === component.state.fields.field_treatment_type) {
                              return item.anamnesis.map(function(question, m){
                                let fieldName = 'anamnesis_' + m;
                                let prev = m - 1;
                                let prevFieldName = 'anamnesis_' + prev;
                                let next = m + 1;
                                let nextFieldName = 'anamnesis_' + next;
                                let fieldNameCheck = 'anamnesis_check_' + m;
                                return (
                                  <TreatmentFormAnamnesisItem key={m} question={question} index={m} fieldName={fieldName} fieldNameCheck={fieldNameCheck} form={component} />
                                );
                              })
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      </MDBCardBody>
                    </MDBCollapse>
                    </>
                  }

                  {component.state.showAgreement &&
                    <>
                    <MDBCollapseHeader onClick={this._onToggleCollapse("collapse1")}>
                      Vereinbarung
                      <i className={ this.state.collapseID==="collapse1" ? "fa fa-angle-down" : "fa fa-angle-up" } />
                    </MDBCollapseHeader>
                    <MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
                      <MDBCardBody>
                        <div className="agreement-bar">
                          {this.state.typeOptions.map(function(item, i){
                            if(item.value === component.state.fields.field_treatment_type) {
                              return item.agreement.map(function(question, j){
                                let fieldName = 'agreement_' + j;
                                let prev = j - 1;
                                let prevFieldName = 'agreement_' + prev;
                                let next = j + 1;
                                let nextFieldName = 'agreement_' + next;
                                return (
                                  <div className={`form-item`} key={'agreement-form-item-' + j}>
                                    <MDBInput
                                      name={fieldName}
                                      label={question.value}
                                      group
                                      type='text'
                                      error='wrong'
                                      success='right'
                                      required
                                      onChange={component._onInputChange}
                                      value={component.state.fields.fieldName}
                                      key={fieldName}
                                    />
                                  </div>
                                );
                              })
                            } else {
                              return null;
                            }
                          })}
                        </div>
                        <div className="agreement-details-bar">
                          {this.state.typeOptions.map(function(item, k){
                            if(item.value === component.state.fields.field_treatment_type) {
                              return item.agreementDetails.map(function(details, l){
                                let fieldName = 'agreement-details_' + l;
                                let prev = l - 1;
                                let prevFieldName = 'agreement-details' + prev;
                                let next = l + 1;
                                let nextFieldName = 'agreement-details' + next;
                                return (
                                  <div className="details-bar" key={l}>
                                    {details.value}
                                    <hr />
                                  </div>
                                );
                              })
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      </MDBCardBody>
                    </MDBCollapse>
                    </>
                  }

                  {component.state.showAgreement &&
                    <>
                      <hr />

                      <div className="advise-bar">
                        <MDBAlert color="warning" >
                          Ich versichere mit meiner Unterschrift, alle erhaltenen Anweisungen, die den Behandlungsablauf während und nach der Anwendung betreffen, zu befolgen.
                          Der Fragebogen wurde ausgefüllt. Ich wurde darüber aufgeklärt, dass der Veyu Salon keine Haftung bei später auftretenden Komplikationen übernimmt. Die Behandlung erfolgt auf meinen ausdrücklichen Wunsch hin und auf mein eigenes Risiko.
                        </MDBAlert>


                        {!component.state.showSignPad &&
                          <MDBBtn
                            color="red"
                            onClick={() => component.setState({showSignPad: true})}
                            >
                            Unterschreiben
                          </MDBBtn>
                        }

                      </div>


                      {!component.state.fields.field_image && component.state.showSignPad &&
                          <>
                            <SignaturePad
                              ref={component.sigCanvas}
                              canvasProps={{
                                className: "signatureCanvas"
                              }}
                            />
                            <hr />
                            {/* Button to trigger save canvas image */}
                            <MDBBtn onClick={component._onSignatureClear} color="red" className="mr-2"><MDBIcon icon="sync" className="mr-2" /> zurücksetzen</MDBBtn>
                            <MDBBtn onClick={component._onSignatureSave} color="green"><MDBIcon far icon="save mr-2" /> Unterschrift speichern</MDBBtn>
                          </>
                      }

                      {component.state.fields.field_image ? (
                        <>
                          <img
                            id="sigCanvasImage"
                            src={component.state.imageURL}
                            alt="my signature"
                            style={{
                              display: "block",
                              width: "600px"
                            }}
                          />
                        <hr />
                        <MDBBtn onClick={component._onSignatureClear} color="red" className="mr-2"><MDBIcon icon="sync" className="mr-2" /> zurücksetzen</MDBBtn>
                        </>
                        ) : null
                      }

                    </>
                  }


                  {component.state.fields.field_image && component.state.showAgreement &&
                    <>
                      <hr />
                      <MDBBtn color="primary" type="submit"><MDBIcon far icon="save mr-2" /> Behandlung speichern</MDBBtn>
                    </>
                  }

                  {!component.state.showAgreement &&
                    <>
                      <hr />
                      <MDBBtn color="primary" type="submit"><MDBIcon far icon="save mr-2" /> Behandlung speichern</MDBBtn>
                    </>
                  }
                </>
              }

            </form>
          </MDBModalBody>
        </MDBModal>
      </section>
      </>
    );
  }
}

export default TreatmentForm;
