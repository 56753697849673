import React, {
  Component
} from 'react';

import CheckupResultRow from "./CheckupResultRow";
import Pager from 'react-pager';
import PermissionHelper from "../../../helpers/PermissionHelper";
import CheckupActions from "./actions/CheckupActions";
import CheckupForm from "./CheckupForm";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';


import config from '../../../config';



class CheckupSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      nid: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_treatment: this.props.nid,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc'
      },
      sortOptions: [
        {
          checked: true,
          text: 'Geändert',
          value: 'nfd.changed'
        }
      ]
    }

    this.formRef = React.createRef();
    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  componentDidMount() {

    console.log(this.props);

    this.loadCheckupCollection();
  }




  loadCheckupCollection() {

      var component = this;


      component.setState({
        collectionLoaded: false
      });


      /** build filter query **/
      CheckupActions._get('All', this.state.filters, this.state.page).then(response => {

        component.setState({
          result: response.data.result,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadCheckupCollection();
    });
  }

  render() {
    let component = this;
    return (
      <>
        <section id="checkup-bar">

          {!this.state.loaded &&
            <div className="text-center">
              <MDBSpinner red />
            </div>
          }

          {this.state.loaded &&  (
            <>

            {!this.props.treatment.field_is_completed[0].value &&
              <CheckupForm searchComponent={this} ref={this.formRef} nid={this.props.nid} />
            }


              <hr />
              { component.state.result.length > 0 && (
                <MDBTable responsive hover striped>
                  <thead>
                    <tr>
                      <th className='th-lg'>
                        Checkupnummer #
                      </th>
                      <th className='th-lg'>
                        Datum
                      </th>
                      <th className='th-lg'>
                        Status
                      </th>
                      <th className='th-lg'>
                        Bemerkung
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {true && (
                      component.state.result.map(function(row, j){
                        console.log(row)
                        return (
                          <CheckupResultRow result={row}  key={'checkup-result-view-' + row.nid + row.changed} searchComponent={component} />
                        )
                      })
                    )}
                  </tbody>
                </MDBTable>
              )}

              {component.state.result.length == 0 &&
                <div className="empty">Keine Termine</div>
              }

              {component.state.loaded && component.state.total > 10 && (
                <>
                  <hr />
                  <Pager
                        total={Math.ceil(this.state.total / this.state.limit)}
                        current={this.state.page}
                        visiblePages={this.state.visiblePage}
                        titles={{ first: '<|', last: '>|' }}
                        className="pagination-sm pull-right"
                        onPageChanged={this._onHandlePageChanged}
                    />
                </>
              )}
            </>
          )}
        </section>
      </>
    );
  }
}

export default CheckupSearch;
