import React from 'react';
import {
  MDBNotification,
  MDBContainer
} from 'mdbreact';
import axios from "axios";
import { history } from '../helpers/history';
import config from '../config';



class AlertComponent extends React.Component {

  constructor(props) {
    super(props);

    let component = this;

    this.state = {
      messageContainer: []
    }

    axios.interceptors.response.use( async(response) => {
        let messageContainer = component.state.messageContainer;
        let messageLength = component.state.messageContainer.length;
        let offset = messageLength * 50;
        /*
        messageContainer.push(
          <MDBNotification
            key={messageLength}
            show
            fade
            iconClassName="text-primary"
            title="Success"
            message='Success'
          />
        );*/
        return response;
    }, function (error) {

      //console.log(error.response.data.message);

      if(error.response && error.response.status == 403) {
        history.push('/pages/login');
        return Promise.reject(error);
      }

      let messageContainer = component.state.messageContainer;
      let messageLength = component.state.messageContainer.length;
      let offset = messageLength * 50;
      let errorMessage = '';

      if(error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else {
        errorMessage = error.message;
      }

      messageContainer.push(
        <MDBNotification
          key={messageLength}
          show
          fade
          iconClassName="text-danger"
          title={'Error:'}
          message={errorMessage}
          autohide={3000}
        />
      );

      component.setState(prevState => ({
        messageContainer: messageContainer,
      }))

      return Promise.reject(error);
    })
  }

  render() {
    return (
      <>
        <MDBContainer
          style={{
            width: "auto",
            position: "fixed",
            top: "50px",
            right: "10px",
            zIndex: 9999
          }}
        >
        {this.state.messageContainer}
        </MDBContainer>
      </>
    );
  }
}

export default AlertComponent;
