import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse,
  MDBTimePicker,
} from 'mdbreact';
import RecommendationActions from './actions/RecommendationActions';
import { history } from '../../../helpers/history';

import MDBFileupload from 'mdb-react-fileupload';

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';

import Image from "../image/Image";

class RecommendationForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      modal: false,
      nid: false,
      entity: this.props.entity,
      loaded: true,
      field_datetime: new Date(Date.now()),
      field_time: '12:00:00',
      body: '',
      field_treatment: false,
    }

    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
  }

  componentDidMount() {



    var recommendedSelect = new Array();
    if(this.props.customers) {
      Object.entries(this.props.customers).forEach(function(item, key){
        var optionItem = {
          text: item[1].title + ' | ' + item[1].street + ' ' + item[1].city,
          value: item[1].nid
        }
        recommendedSelect.push(optionItem);
        //console.log(item);
      });
    }

    console.log(recommendedSelect);
    this.setState(prevState => ({
      recommendedSelect: recommendedSelect
    }));



    if(this.props.entity) {
      this.setState(prevState => ({
        loaded: true,
        nid: this.props.entity.nid[0].value,

      }))
    } else {
      if(PermissionHelper._hasPermission('ignore locations') === false) {

      }
    }
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  _onSubmit(e) {
    e.preventDefault();
    var component = this;

    if(component.state.hasSubmitted) {
      return;
    }

    component.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))

    let values = {
      field_customer: this.props.nid,
      field_recommended: this.state.field_recommended,
      body: this.state.body,
    }

    if(!this.state.nid) {
      RecommendationActions._post(values)
        .then(response => {


          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))

          component._onToggleModal();
          component._onFormReset();

          if(component.props.searchComponent) {
            component.props.searchComponent.loadRecommendationCollection();
          }

        });
    } else {
      RecommendationActions._patch(values)
        .then(response => {

          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))

          component._onToggleModal();
          if(component.props.searchComponent) {
            component.props.searchComponent.loadRecommendationCollection();
          }

        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      nid: false,
      field_datetime: new Date(Date.now()),
      body: '',
      field_treatment: false,
    }))
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    let component = this;
    return (
      <>
        {!this.state.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neue Empfehlung erfassen</MDBBtn>
        }


        <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal} size="lg">
          <MDBModalHeader toggle={this._onToggleModal}>Empfehlungen</MDBModalHeader>
          <MDBModalBody>
            <MDBCard className='mt-0'>
                <form validate="true" onSubmit={this._onSubmit}>

                  <MDBRow>
                    <MDBCol className="text-left">
                      <MDBSelect
                        search
                        ref={this.recommendedSelect}
                        options={this.state.recommendedSelect}
                        selected="Bitte auswählen"
                        label="Empfehlung"
                        name="field_recommended"
                        id="field_recommended"
                        getValue={(e) => {
                          this._onSelectChange({
                            target: {
                              name: 'field_recommended',
                              value: e,
                            },
                          });
                        }}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol size="12">
                      <MDBInput type="textarea" label="Bemerkung" rows="3" name="body" value={this.state.body} onChange={this._onInputChange} required />
                    </MDBCol>
                  </MDBRow>

                  <MDBBtn color="primary" disabled={this.state.hasSubmitted ? true : false}  type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>

                </form>
            </MDBCard>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}

export default RecommendationForm;
