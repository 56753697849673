import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';

import Select from 'react-select';

import moment from 'moment';
import 'moment/locale/de';

import SessionActions from './actions/SessionActions';



class SessionEditForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      zoneOptions: [],
      loaded: true,
      modal: false,
      parameters: [],
      parameterValues: [],
      body: '',
      nid: this.props.session.nid[0].value
    }

    this._onSubmit = this._onSubmit.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onChangeParamter = this._onChangeParamter.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onPreventToggle = this._onPreventToggle.bind(this);
  }

  componentDidMount() {

    let component = this;

    //console.log(this.props);

    var zoneOptions = this.state.zoneOptions;
    this.props.zones.map(function(item, n){
      var zoneOptionObj = {}
      zoneOptionObj.label = item.value;
      zoneOptionObj.value = item.value;
      zoneOptions.push(zoneOptionObj);
    });
    this.setState({zoneOptions: zoneOptions});

    var parameterValues = new Array();
    Object.values(JSON.parse(this.props.session.field_parameters[0].value)).map(function(item, i){
        //console.log(item);
        let fieldName = i;
        let prev = i - 1;
        let prevFieldName = prev;

        var key = Object.keys(item);
        var value = Object.values(item);
        parameterValues.push(value);
    })

    this.setState({parameterValues: parameterValues});


    if(this.props.session.body[0]) {
      this.setState({body: this.props.session.body[0].value});
    }


  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  _onChange(value) {
    let inputValue = value.target.value;
    let inputName = value.target.name

    this.setState(prevState => ({
        [inputName]: inputValue
    }))
  }

  _onChangeParamter(value) {

    let inputValue = value.target.value;
    let inputName = value.target.name;

    //console.log(inputValue);
    //console.log(inputName);

    let parameterValues = this.state.parameterValues;
    parameterValues[inputName] = inputValue;

    this.setState({
      parameterValues: parameterValues
    })

    //console.log(this.state);
  }

  _onSelectChange(value, paramterName) {
    let component = this;

    let parameterValues = this.state.parameterValues;
    parameterValues[paramterName] = value;

    this.setState({
      parameterValues: parameterValues
    })

    //console.log(this.state);
  }

  _onSubmit(event) {
    var component = this;
    event.preventDefault();
    //console.log(this.state);
    component.setState({
      loaded: false
    })

    SessionActions._patch(this.state).then(response => {
      component._onToggleModal();
      component.setState({
        body: ''
      })

      component.props.searchComponent.loadSessionCollection();
    });
  }


  _onPreventToggle() {
    return false;
  }



  render() {
    let component = this;

    var paramValues = Object.values(JSON.parse(this.props.session.field_parameters[0].value));
    const renderParams = paramValues.map((item, i) => {
      var values = Object.values(item);
      var keys = Object.keys(item);
      return (<td key={i}>{values[0]}</td>);
    });

    return (
      <>

      {this.props.session &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


      <MDBModal isOpen={this.state.modal} toggle={this._onPreventToggle} size="lg">
        <MDBModalHeader toggle={this._onToggleModal}>Sitzung bearbeiten</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0'>



            <h3>Sitzungsinformation</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="item">
                  <div className="label">Standort</div>
                  <div className="value">
                    {this.props.location}
                  </div>
                </div>
                <div className="item">
                  <div className="label">Behandelt von</div>
                  <div className="value">
                    {this.props.author}
                  </div>
                </div>
                <div className="item">
                  <div className="label">Datum</div>
                  <div className="value">
                    {moment(this.props.session.created[0].value).format("dddd, DD MMMM YYYY")}
                  </div>
                </div>
              </div>
            </div>

            <hr />


            <form validate="true" onSubmit={this._onSubmit}>




                      <>



                      {Object.values(JSON.parse(this.props.session.field_parameters[0].value)).map(function(item, i){
                          //console.log(item);
                              let fieldName = i;
                              let prev = i - 1;
                              let prevFieldName = prev;

                              var key = Object.keys(item);
                              var value = Object.values(item);
                              //console.log(key);
                              //console.log(value);


                                  if(key[0] == "Zone" && (component.props.treatment.field_treatment_type[0].target_id == 37 || component.props.treatment.field_treatment_type[0].target_id == 5)) {

                                    var defaultValue = false;

                                    defaultValue = {
                                      label: value,
                                      value: value
                                    }

                                    return (
                                      <div className="input-item" key={i}>
                                        <Select
                                          key={'session_' + i}
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          placeholder=""
                                          options={component.state.zoneOptions}
                                          defaultValue={defaultValue}
                                          isClearable={true}
                                          isSearchable={true}
                                          onChange={(value) => {


                                            //console.log(value);

                                            if(value) {
                                              value = value.value;
                                            } else {
                                              value = '';
                                            }

                                            component._onSelectChange(value, fieldName);
                                          }}
                                        />
                                    </div>)
                                  } else {
                                    return (
                                      <div className="input-item" key={i}>
                                    <MDBInput
                                      name={fieldName}
                                      label={key[0]}
                                      group
                                      type='text'
                                      error='wrong'
                                      success='right'
                                      onChange={component._onChangeParamter}
                                      value={component.state.parameterValues[fieldName]}
                                      key={i}
                                    />
                                </div>)
                                }
                        })}

                        <div className="input-item">
                          <MDBInput
                            name="body"
                            label="Bemerkung"
                            group
                            type='text'
                            error='wrong'
                            success='right'
                            onChange={component._onChange}
                            value={this.state.body}
                          />
                        </div>

                        <MDBBtn color="primary" type="submit"><MDBIcon far icon="save mr-2" /> speichern</MDBBtn>



                        </>


            </form>


          </MDBCard>
        </MDBModalBody>
      </MDBModal>

      </>


    );
  }
}

export default SessionEditForm;
