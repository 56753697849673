import React from 'react';
import {
  MDBNotification,
  MDBContainer
} from 'mdbreact';
import axios from "axios";
import config from '../config';



class JwtComponent extends React.Component {

  constructor(props) {
    super(props);

    let component = this;
    axios.interceptors.request.use( async(requestConfig) => {
        /**
         * Add CSRF Token and jwt token to requests
        */
        //alert(requestConfig.url);
        if(!requestConfig.url.includes("session/token")) {

          /*
          axios.get(`${config.backend_base}session/token`).then(result => {
            requestConfig.headers['X-CSRF-Token'] = result.data;
          })*/

          if(localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            requestConfig.headers['Authorization'] = 'Bearer ' + user.access_token;
          }
        }
        return requestConfig;
    }, function (error) {
      alert(error.message);
      return Promise.reject(error)
    })
  }

  render() {
    return (
      <>
      </>
    );
  }
}

export default JwtComponent;
