import React from 'react';
import {
  MDBSideNavLink,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon,
  MDBSelect,
  MDBLink,
  MDBSideNavCat
} from 'mdbreact';
import { connect } from 'react-redux';
import userActions from '../actions/userActions';
import Logo from '../assets/images/veyu-logo.svg' // relative path to image

import PermissionHelper from "../helpers/PermissionHelper";
import TaskTeamMenuLink from "./modules/task/TaskTeamMenuLink";
import TaskMenuLink from "./modules/task/TaskMenuLink";
import Select from 'react-select'



class SideNavigation extends React.Component {

  constructor(props) {
    super(props);

    //console.log(props);
    this.state = {
      isOpen: false,
      locationOptions: this.props.locations,
      selectedLocation: localStorage.getItem('selectedLocation'),
      selectedLocationOption: false,
      buttonStyle: {
        transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
        opacity: "0"
      }
    }

    this.locationSelect = React.createRef();

    this._onLocationChange = this._onLocationChange.bind(this);
  }

  componentDidMount() {
    var component = this;
    //console.log(this);
    //console.log(localStorage.getItem('selectedLocation'));

    var locations = this.props.locations;
    var locationOptions = [];
    //console.log(locations);
    var selectedLocationOption = false;

    if(this.props.locations) {
      locations.forEach(function(item){
        var optionItem = {
          label: item.title[0].value,
          value: item.nid[0].value.toString()
        }
        var selectedLocation = localStorage.getItem('selectedLocation');



        if(selectedLocation == item.nid[0].value.toString()) {

          //console.log(selectedLocation);
          //console.log(item.nid[0].value.toString());

          selectedLocationOption = {
            label: item.title[0].value,
            value: item.nid[0].value.toString()
          }

          //console.log(selectedLocationOption);

          component.setState({
            selectedLocationOption: selectedLocationOption
          });
        }
        locationOptions.push(optionItem);
        //console.log(optionItem);
      });

      this.setState({
        locationOptions: locationOptions,
      });
    }
  }

  componentWillUnmount() {
  }

  _onLocationChange(value) {
    console.log(value);
    if(value != this.state.selectedLocation && value && value != '') {
      this.setState({
        selectedLocation: value
      });
      localStorage.setItem('selectedLocation', value);
      window.location.reload();
    }
  };


  render() {
    //console.log(user);
    const { onLinkClick } = this.props;
    var component = this;
    return (
      <div className='white-skin' id="side-navigation">






        <MDBSideNav
          fixed
          breakWidth={this.props.breakWidth}
          triggerOpening={this.props.triggerOpening}
          style={{ transition: 'padding-left .3s' }}
        >
          <li>
            <div className="page-logo">
              <MDBLink to={`/dashboard`} className="d-inline">
                <img alt="" src={Logo} className="img-fluid" />
              </MDBLink>
            </div>
          </li>

          <MDBSideNavNav>
            <MDBSideNavLink topLevel to='/dashboard' onClick={onLinkClick}>
              <MDBIcon icon='chart-pie mr-2' />
              Dashboard
            </MDBSideNavLink>
            <MDBSideNavLink topLevel to='/customer' onClick={onLinkClick}>
              <MDBIcon icon="users mr-2" />
              Kunden
            </MDBSideNavLink>
            <MDBSideNavLink topLevel to='/employee' onClick={onLinkClick}>
              <MDBIcon far icon="address-card mr-2" />
              Mitarbeiter
            </MDBSideNavLink>
            <MDBSideNavLink topLevel to='/location' onClick={onLinkClick}>
              <MDBIcon icon="globe mr-2" />
              Standorte
            </MDBSideNavLink>
            <TaskMenuLink onClick={onLinkClick} />

            <TaskTeamMenuLink onClick={onLinkClick} />
            <MDBSideNavLink topLevel to='/news' onClick={onLinkClick}>
              <MDBIcon icon="newspaper mr-2" />
              News
            </MDBSideNavLink>
          </MDBSideNavNav>




            <>
            <div className="location-select">
              {this.state.selectedLocationOption &&
                <>
                <label>Standort wählen</label>
                  <Select
                    options={this.state.locationOptions}
                    key={'location-select'}
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Standort wählen"
                    menuPlacement={'top'}
                    defaultValue={{label: this.state.selectedLocationOption.label, value: this.state.selectedLocationOption.value}}
                    isClearable={true}
                    isSearchable={false}
                    onChange={(value) => {


                      console.log(value);

                      if(value) {
                        value = value.value;
                      } else {
                        value = '';
                      }

                      this._onLocationChange(value);
                    }}
                  />
                </>
              }

              <br />

            </div>
            </>





          <button className="btn btn-logout" onClick={this.props.logout}>
            <MDBIcon icon="sign-out-alt mr-2" /> Logout
          </button>




        </MDBSideNav>
      </div>
    );
  }
}

function mapState(state) {
    const { authenticationReducer } = state;
    const { user, locations } = authenticationReducer;
    return { user, locations };
}

const actionCreators = {
  logout: userActions.logout
}

const connectedSideNavigation = connect(mapState, actionCreators)(SideNavigation);
export { connectedSideNavigation as SideNavigation };
