import React from 'react';
import {
  MDBSpinner,
  MDBCardImage
} from 'mdbreact';

import ContentLoader, { Facebook } from 'react-content-loader'


import axios from "axios";


import config from '../../../config';

class Image extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      data: '',
      loaded: false
    }
  }


  componentWillReceiveProps(nextProps) {
    if(this.props.fid == nextProps.fid) {
      return;
    }
    this.initImage(nextProps.fid);
  }

  componentDidMount() {
    this.initImage(this.props.fid);
  }

  initImage(fid) {

    this.setState({
      loaded: false
    });

    let component = this;
    if(fid.indexOf("http") == 1)
    {
      component.setState({
        data: fid,
        loaded: true
      });
      return;
    }

    // Load image by this.props.fid
    let data = axios.get(`${config.backend_base}rest/image_api/${fid}?_format=json&imagestyle=${this.props.imagestyle}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        //console.log(response);

        component.setState({
          data: response.data.image,
          loaded: true
        });
      })
  }


  render() {
    return (

      <>

      {!this.state.loaded &&
        <>
          <div class="image-preloader" style={{ width: this.props.width + 'px', height: this.props.height + 'px' }}>

            <ContentLoader
              speed={0.5}
              width={400}
              height={160}
              viewBox="0 0 400 160"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
              <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
              <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
              <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
              <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
              <circle cx="20" cy="20" r="20" />
            </ContentLoader>


          </div>
        </>

      }
      {this.state.loaded &&
        <MDBCardImage className="img-fluid" src={this.state.data}  waves />
      }


      </>

    )
  }

}
export default Image;
