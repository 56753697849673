import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';



import CustomerSearch from "./CustomerSearch";



/** import styles **/
import '../../../assets/css/sections/customer.css';


class CustomerOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <section id="customer-overview">
        <MDBCard className="card-customer">
          <MDBCardBody>
            <MDBCardTitle><MDBIcon icon="users mr-2" /> Kunden</MDBCardTitle>
            <MDBCardText tag="div">
              <CustomerSearch />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default CustomerOverview;
