import React, { Component } from 'react';
import Pager from 'react-pager';
import NewsResultRow from "./NewsResultRow";
import NewsActions from "./actions/NewsActions";
import Image from "../image/Image";
import NewsFilter from "./NewsFilter";
import NewsForm from "./NewsForm";
import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import PermissionHelper from "../../../helpers/PermissionHelper";

import config from '../../../config';


class NewsSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 8,
      total: 0,
      visiblePage: 5,
      newsCategoriesFilter: [],
      locationsFilter: [],
      filters: {
        field_news_category: null
      }
    }


    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadNewsCollection();
    });
  }

  componentDidMount(){
    this.loadNewsCollection();
    //console.log(this);
  }

  loadNewsCollection() {


      var component = this;

      /** build filter query **/

      NewsActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.result);


        var newsCategories = response.data.newsCategories;
        var newsCategoriesFilter = new Array();
        if(newsCategories) {
          Object.entries(newsCategories).forEach(function(item, key){
            var optionItem = {
              checked: (item[0] == component.state.filters.field_news_category) ? true : false,
              text: item[1].name[0].value,
              value: item[0]
            }
            newsCategoriesFilter.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.newsCategoriesFilter.length == 0) {
          component.setState({
            newsCategoriesFilter: newsCategoriesFilter,
            newsCategories: newsCategories,
          });
        }


        var locations = response.data.locations;
        var locationsFilter = new Array();
        if(locations) {
          Object.entries(locations).forEach(function(item, key){
            var optionItem = {
              text: item[1].title[0].value,
              value: item[0]
            }
            locationsFilter.push(optionItem);
            //console.log(item);
          });
        }


        if(this.state.locationsFilter.length == 0) {
          component.setState({
            locationsFilter: locationsFilter,
            locations: locations,
          });
        }

        //console.log(newsCategoriesFilter);

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }




        component.setState({
          result: result,
          loaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;
    return (
      <>

        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'news-loader'} red /></div>
        }

        {component.state.loaded && (
          <>


          {PermissionHelper._hasPermission('restful post News') === true &&
            <>
              <NewsForm searchComponent={this} ref={this.formRef} />
            </>
          }


          {/*<NewsFilter searchComponent={this} /><hr />*/}

            { component.state.result.length > 0 && (
              <MDBRow>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <NewsResultRow result={row[1]}  key={'news-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
              </MDBRow>
            )}

            {component.state.result.length == 0 &&
              <div className="empty">Keine News vorhanden</div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default NewsSearch;
