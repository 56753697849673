import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar
} from 'mdbreact';

import Image from "../image/Image";

import NumberFormat from 'react-number-format';


import EmployeeActions from "./actions/EmployeeActions";
import EmployeeForm from "./EmployeeForm";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";



class EmployeeResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      EmployeeActions._delete(this.props.result.uid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadEmployeeCollection();
        });
    }
  }

  render() {
    //console.log(this.props);
    return (
      <tr className="employee-item" key={'employee-' + this.props.result.uid + this.props.result.changed}>

        <td>{(this.props.result.user.field_employee_number[0] ? this.props.result.user.field_employee_number[0].value : '')}</td>
        <td>{(this.props.result.user.field_firstname[0] ? this.props.result.user.field_firstname[0].value : '')}</td>
        <td>{(this.props.result.user.field_lastname[0] ? this.props.result.user.field_lastname[0].value : '')}</td>
        <td>{(this.props.result.mail ? this.props.result.mail : '')}</td>
        <td>{(this.props.result.user.field_mobile[0] ? <NumberFormat displayType={'text'} format="+## ## ### ## ##" mask="_" value={this.props.result.user.field_mobile[0].value}/> : '')}</td>

        <td className="text-right">

          <div className="action-bar">
            <MDBBtn color="info" onClick={this._onModalToggle}><MDBIcon icon="info-circle" /></MDBBtn>
            {PermissionHelper._hasPermission('administer users') === true &&
              <>
                <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                <EmployeeForm key={'employee-edit-form-' + this.props.result.uid + this.props.result.changed} entity={this.props.result.user} locations={this.props.result.locations} logindays={this.props.result.logindays} roles={this.props.result.roles} searchComponent={this.props.searchComponent} />
              </>
            }
          </div>



            <MDBModal isOpen={this.state.modal} toggle={this._onModalToggle} size="lg">
              <MDBModalBody>
                <MDBRow>
                  <MDBCol className="mb-lg-0 mb-5 text-center">


                    {/*
                    {this.props.result.user.field_image[0] &&
                      <div className="avatar mx-auto rounded-circle z-depth-1 img-fluid">
                        <Image fid={this.props.result.user.field_image[0].target_id.toString()} imagestyle="employee_detail" className="rounded-circle z-depth-1 img-fluid" />
                      </div>
                    }*/}

                    <h5 className="font-weight-bold mt-4 mb-3">
                      {this.props.result.user.field_firstname[0].value} {this.props.result.user.field_lastname[0].value} (#{this.props.result.user.field_employee_number[0].value})
                    </h5>

                      <div className="text-bar">
                        <strong>Rollen:</strong>
                        <ul className="role-list">
                          {
                            this.props.result.roles.map(function(item, i){
                              //console.log(item);
                              return <li key={'role-' + i}><span>{item}</span></li>
                            })
                          }
                        </ul>
                      </div>


                      <div className="text-bar">
                        <strong>Standorte:</strong>
                        <ul className="location-list">
                          {
                            this.props.result.locations.map(function(item, i){
                              //console.log(item);
                              return <li key={'location-' + i}><span>{item.title[0].value}</span></li>
                            })
                          }
                        </ul>
                      </div>

                      <div className="text-bar">
                        <span className="phone"><strong>Geburtstag:</strong> {moment(this.props.result.user.field_birthday[0].value).format('DD.MM.YYYY')}</span>
                      </div>
                      <div className="text-bar">
                        <span className="phone">
                          <strong>Telefon: </strong>
                          {(this.props.result.user.field_phone[0] ? <NumberFormat displayType={'text'} format="+## ## ### ## ##" mask="_" value={this.props.result.user.field_phone[0].value}/> : '')}
                        </span>
                      </div>
                      <div className="text-bar">
                        <span className="phone">
                          <strong>Mobil: </strong>
                          {(this.props.result.user.field_mobile[0] ? <NumberFormat displayType={'text'} format="+## ## ### ## ##" mask="_" value={this.props.result.user.field_mobile[0].value}/> : '')}
                        </span>
                      </div>
                      <div className="text-bar">
                        <span className="email"><strong>Email:</strong> {this.props.result.mail}</span>
                      </div>
                      <div className="text-bar">
                        <strong>Adresse:</strong><br />
                        <span className="street">{this.props.result.user.field_street[0].value}</span><br />
                        <span className="street">{this.props.result.user.field_zip[0].value}</span> <span className="street">{this.props.result.user.field_city[0].value}</span>
                      </div>

                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="primary" onClick={this._onModalToggle}>schliesen</MDBBtn>
              </MDBModalFooter>
            </MDBModal>

        </td>
      </tr>
    );
  }
}

export default EmployeeResultRow;
