import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar,
  MDBModalHeader,
  MDBTooltip,
  MDBBadge
} from 'mdbreact';


import Image from "../image/Image";
import NumberFormat from 'react-number-format';
import CheckupActions from "./actions/CheckupActions";
import CheckupForm from "./CheckupForm";

import CheckupComment from "../comment/CommentModalSearch.jsx";


import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";



class CheckupResultRow extends React.Component {

  constructor(props) {
    super(props);


    this.checkupCommentRef = React.createRef();

    this.state = {
      modal: false,
    };




    this._onDelete = this._onDelete.bind(this);
    this._onSuccess = this._onSuccess.bind(this);
    this._onFailure = this._onFailure.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }


  componentDidMount() {
  }

  componentDidUpdate() {

  }


  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      CheckupActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadCheckupCollection();
        });
    }
  }

  _onSuccess(e) {
    var component = this;

    const r = window.confirm("Confirm Success");
    if (r === true) {

      var values = {
        nid: this.props.result.nid,
        field_success: 1
      }

      CheckupActions._patch(values)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadCheckupCollection();
        });
    }
  }

  _onFailure(e) {
    var component = this;

    const r = window.confirm("Confirm Failure");
    if (r === true) {

      var values = {
        nid: this.props.result.nid,
        field_failure: 1
      }

      CheckupActions._patch(values)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadCheckupCollection();
        });
    }
  }

  render() {

    var showStatusButtons = true;

    if((this.props.result.node.field_success[0] && this.props.result.node.field_success[0].value) || (this.props.result.node.field_failure[0] && this.props.result.node.field_failure[0].value)) {
      showStatusButtons = false;
    }

    return (
      <tr className="checkup-item" key={'checkup-' + this.props.result.nid + this.props.result.change}>

        <td>{(this.props.result.node.field_checkup_number[0] ? this.props.result.node.field_checkup_number[0].value : '')}</td>
        <td>{(this.props.result.node.field_datetime[0] ? moment(this.props.result.node.field_datetime[0].value).format("DD.MM.YYYY HH:mm") : '')}</td>
        <td>
          {this.props.result.node.field_failure[0] && this.props.result.node.field_failure[0].value &&
            <MDBIcon icon="ban" />
          }

          {this.props.result.node.field_success[0] && this.props.result.node.field_success[0].value &&
            <MDBIcon far icon="check-square" />
          }
        </td>
        <td>{(this.props.result.node.body[0] ? this.props.result.node.body[0].value : '')}</td>
        <td>

          <div className="action-bar">





            <CheckupComment showImportant={false} entityId={this.props.result.node.nid[0].value} entityType="node" fieldName="field_checkup_comment" commentType="checkup_comment" />





            {PermissionHelper._hasPermission('restful delete Checkup') === true &&
              <>
                <MDBBtn className="mr-2" color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
              </>
            }
            {false && PermissionHelper._hasPermission('edit any checkup content') === true &&
              <>
                <CheckupForm key={'checkup-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} />
              </>
            }
          </div>



        </td>
      </tr>
    );
  }
}

export default CheckupResultRow;
