import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBAnimation,
  MDBNavLink,
  MDBIcon,
  MDBSpinner,
  MDBAlert,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';



class TreatmentFormAnamnesisItem extends Component {

  constructor(props) {
    super(props);
    /** states **/

    this.state = {
      showInput: false
    }

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
  }

  componentDidMount() {
    var fieldName = this.props.fieldName;

    var fields = this.props.form.state.fields
    fields[fieldName] = 'Nein';
    this.props.form.setState({
      fields: fields
    });

  }

  _onCheckboxChange(e) {
    //console.log(e.target.checked);
    this.setState({
      showInput: e.target.checked
    },() => {
        //console.log(this.state);
    });

  }


  _onInputChange(e) {

    //console.log(e);
    var fields = this.props.form.state.fields;
    fields[e.target.name] = e.target.value;

    this.props.form.setState({
      fields: fields,
    });
  }

  render() {
    return (
      <div className={`form-item`} key={'anamnesis-form-item' + this.props.index}>
        <MDBInput label={this.props.question.value} name={this.props.fieldNameCheck} type="checkbox" id={this.props.fieldNameCheck} onChange={this._onCheckboxChange} />
        {this.state.showInput &&
          <MDBInput
            name={this.props.fieldName}
            id={this.props.fieldName}
            group
            type='text'
            error='wrong'
            success='right'
            required
            onChange={this._onInputChange}
            value={this.state.fieldNameCheck}
            key={this.props.fieldName}
          />
        }
      </div>
    )
  }
}

export default TreatmentFormAnamnesisItem;
