import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBTable
} from 'mdbreact';

import {DebounceInput} from 'react-debounce-input';

import CustomerActions from "./actions/CustomerActions";
import CustomerRecommendationRow from "./CustomerRecommendationRow";
import Pager from 'react-pager';



class CustomerRecommendationSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        field_searchterm: '',
        field_location: null,
        field_treatments: null,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc'
      },
      collectionLoaded: true,
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      result: [],
    }


    this._onInputChange = this._onInputChange.bind(this);
    this._onCustomerSearch = this._onCustomerSearch.bind(this);
    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
  }

  componentDidMount() {
  }


  _onCustomerSearch() {
    var component = this;
    /** build filter query **/
    CustomerActions._get('All', this.state.filters, this.state.page).then(response => {


        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }




        component.setState({
          result: result,
          loaded: true,
          collectionLoaded: true,
          channelOptions: response.data.channelOptions,
          total: response.data.total
        });

      });
  }

  _onInputChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.state.filters;
    filters[name] = value;


    this.setState({
        filters: filters,
        page: 0
    }, () => {
        //var customerFilter = this.props.searchComponent.state;
        //localStorage.setItem('customerFilter', JSON.stringify(customerFilter));
        this._onCustomerSearch();
    });
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this._onCustomerSearch();
    });
  }

  render() {

    var component = this;

    return (
      <section id="customer-recommendation-search">
        <MDBRow>
          <MDBCol>
              <div className="sui-search-box">
                <div className="sui-search-box__wrapper">
                  <DebounceInput
                    minLength={3}
                    debounceTimeout={800}
                    id="field_searchterm"
                    name="field_searchterm"
                    placeholder="Kundenummer, E-Mail, Name"
                    className="sui-search-box__text-input "
                    onChange={(e) => {
                      this._onInputChange({
                        target: {
                          name: 'field_searchterm',
                          value: e.target.value,
                        },
                      });
                    }}
                    value={this.state.filters.field_searchterm}
                  />

                 {!this.state.collectionLoaded && (
                   <><MDBSpinner red small /></>
                 )}
                </div>
              </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
            <hr />
            { component.state.result.length > 0 && (
              <MDBTable responsive hover striped>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Kundennummer #
                    </th>
                    <th className='th-lg'>
                      Vorname
                    </th>
                    <th className='th-lg'>
                      Nachname
                    </th>
                    <th className='th-lg'>
                      Ort
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <CustomerRecommendationRow form={component.props.form} result={row[1]}  key={'customer-result-view-' + row[1].nid + row[1].changed} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}

            {component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}

          </MDBCol>
        </MDBRow>
      </section>
    );
  }
}

export default CustomerRecommendationSearch;
