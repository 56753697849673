import React, { Component } from 'react';

import {
  MDBSpinner,
  MDBLightbox,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon
} from 'mdbreact';

import GalleryActions from "./actions/GalleryActions";
import GalleryForm from "./GalleryForm";

import Lightbox from "react-image-lightbox";
import "../../../assets/css/sections/gallery.css";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';


class GallerySearch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      total: 0,
      result: [],
      loaded: false,
      collectionLoaded: false,
      filters: {
        nid: this.props.nid
      },
      page: 0,
      images: []
    }

    this._onDelete = this._onDelete.bind(this);
    this.loadGalleryCollection = this.loadGalleryCollection.bind(this);
    this._onRenderImages = this._onRenderImages.bind(this);
  }

  componentDidMount(){
    this.loadGalleryCollection();
  }

  loadGalleryCollection() {
    var component = this;

    component.setState({
      loaded: false,
      images: [],
      collectionLoaded: false
    });

    /** build filter query **/
    GalleryActions._get('All', this.state.filters, this.state.page).then(response => {
      var images = [];
      if(response.data.result) {
        Object.entries(response.data.result).map((node, i) => {
          let nodeObject = node[1].node;
          if(nodeObject.title[0] && nodeObject.body[0]) {
            let element = {
              nid: nodeObject.nid[0].value,
              title: nodeObject.title[0].value,
              body: nodeObject.body[0].value,
            }

            if(nodeObject.field_image[0]) {
              element.href = node[1].image;
              element.src = node[1].thumb;
              images.push(element);
            }
          }
        })
      }

      component.setState({
        images: images,
        loaded: true,
        collectionLoaded: true
      });
    });
  }

  _onDelete(e) {
    let nid = e.target.getAttribute('data-nid');
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      GalleryActions._delete(nid)
        .then(response => {
          component.loadGalleryCollection();
        });
    }
  }

  _onRenderImages = () => {
    let photoIndex = -1;
    const { images } = this.state;
    return images.map(imageSrc => {
      photoIndex++;
      const privateKey = photoIndex;
      return (
        <MDBCol md="3" key={photoIndex}>
          <div className="action-bar">
            {PermissionHelper._hasPermission('restful delete Gallery') === true &&
              <>
                <MDBBtn color="danger" data-nid={imageSrc.nid} onClick={this._onDelete}><MDBIcon data-nid={imageSrc.nid} far icon="trash-alt" /></MDBBtn>
              </>
            }
          </div>
          <figure>
            <a alt="Gallery" onClick={()=>
            this.setState({ photoIndex: privateKey, isOpen: true })
          }>
              <img src={imageSrc.src} className="img-fluid" />
            </a>
          </figure>
        </MDBCol>
        );
    })
  }

  render() {

    let component = this;
    const { photoIndex, isOpen, images } = this.state;

    return (
        <>
          <GalleryForm entity={this.props.nid} searchComponent={this} />
          <hr />
          {!this.state.loaded &&
            <div className="text-center">
              <MDBSpinner red />
            </div>
          }

          {component.state.images.length == 0 &&
            <div className="empty">Keine Bilder vorhanden</div>
          }

          {this.state.loaded &&
            <>
              <div className="mdb-lightbox margin">
                <MDBRow>
                  {this._onRenderImages()}
                </MDBRow>
              </div>
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex].href}
                  nextSrc={images[(photoIndex + 1) % images.length].href}
                  prevSrc={images[(photoIndex + images.length - 1) % images.length].href}
                  imageTitle={images[(photoIndex) % images.length].title  + ' | ' + moment(images[(photoIndex) % images.length].created).format("DD.MM.YYYY HH:mm")}
                  imageCaption={images[(photoIndex) % images.length].body}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + images.length - 1) % images.length
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      photoIndex: (photoIndex + 1) % images.length
                    })
                    }
                />
              )}
            </>
          }
        </>
    );
  }
}

export default GallerySearch;
