import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody
} from 'mdbreact';


import LocationActions from "./actions/LocationActions";
import LocationForm from "./LocationForm";

import Image from "../image/Image";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';

class LocationResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      center: {
        lat: 59.95,
        lng: 30.33
      },
      zoom: 11,
      dayNames: [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag'
      ]
    };

    this._onDelete = this._onDelete.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      LocationActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadLocationCollection();
        });
    }
  }



  render() {

    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    let component = this;

    return (
      <MDBCol xl="3" lg="6" md="6" className="mb-4 location-item">
        <div className="action-bar">
          {PermissionHelper._hasPermission('edit any location content') === true &&
            <>
              <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
              <LocationForm key={'location-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} />
            </>
          }
        </div>
        <img className="img-fluid img-thumbnail" src={this.props.result.thumbnail} />
        <h4 className="font-weight-bold mb-3 mt-3"><strong>{this.props.result.title}</strong></h4>
          <div className="address">
            {this.props.result.field_street}<br />
            {this.props.result.field_zip} {this.props.result.field_city}<br />
            Schweiz
          </div>
          <hr />
          <div className="contact">
            <span className="phone"><MDBIcon icon="phone mr-2" />{this.props.result.field_phone}</span><br />
            <span className="email"><MDBIcon icon="envelope mr-2" />{this.props.result.field_email}</span><br />
            <span className="opening">
              <MDBPopover placement="right" popover clickable id="popover-opening">
                <MDBBtn flat><MDBIcon far icon="clock mr-2" />Öffnungszeiten</MDBBtn>
                <div>
                  <MDBPopoverHeader>Öffnungszeiten</MDBPopoverHeader>
                  <MDBPopoverBody>
                    <ul className="opening-list">
                      {
                        this.props.result.field_opening.map(function(item, i){
                          return <li key={i}><span>{component.state.dayNames[i]}:</span> <span>{item}</span></li>
                        })
                      }
                    </ul>
                  </MDBPopoverBody>
                </div>
              </MDBPopover>
            </span>
          </div>
      </MDBCol>
    );
  }
}

export default LocationResultRow;
