import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';


import ScanActions from "./actions/ScanActions";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class ScanResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      ScanActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadScanCollection();
        });
    }
  }

  _onEdit(e) {

  }

  render() {

    //console.log(this.props.result);

    return (
      <tr className="scan-item">
        <td>{ this.props.result.node.title[0].value }</td>
        <td className="text-right">
          <div className="action-bar">
            <MDBBtn onClick={this._onModalToggle} color="primary" ><MDBIcon far icon="file-pdf" /></MDBBtn>
              {PermissionHelper._hasPermission('restful delete Scan') === true &&
                <>
                  <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                </>
              }
          </div>

          <MDBModal className="text-left" isOpen={this.state.modal} toggle={this._onModalToggle} size="lg">
            <MDBModalBody>
              {this.props.result.node.field_file[0].url &&
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <div style={{ height: '750px' }}>
                        <Viewer fileUrl={this.props.result.field_file} />
                    </div>
                </Worker>
              }
              <hr />

              {this.props.result.node.body[0] &&
                <>
                <span dangerouslySetInnerHTML={{__html: this.props.result.node.body[0].value}} />
                </>
              }

            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this._onModalToggle}>schliessen</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

        </td>
      </tr>
    );
  }
}

export default ScanResultRow;
