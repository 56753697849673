import React, { Component } from 'react';

import SessionResultRow from "./SessionResultRow";
import SessionForm from "./SessionForm";

import SessionActions from "./actions/SessionActions";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';

import {
  MDBTable,
  MDBSpinner
} from 'mdbreact';

class SessionSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      treatmentType: null,
      filters: {
        field_treatment: this.props.nid
      }
    }
  }


  componentDidMount() {
    this.loadSessionCollection();
  }


  loadSessionCollection() {
      var component = this;

      /** build filter query **/
      SessionActions._get('All', this.state.filters, this.state.page).then(response => {

        //console.log(response);

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        var treatmentType = null;;
        if(response.data.treatmentType) {
          treatmentType = response.data.treatmentType;
        }

        //console.log(treatmentType);

        var lastItem = result[0];
        if(lastItem) {
          lastItem = lastItem[1][0];

          component.props.treatmentDetail.setState({
            lastUpdate: lastItem.changed
          });
        }




        component.setState({
          result: result,
          loaded: true,
          treatmentType: treatmentType,
          total: response.data.total
        });

      });
  }


  _onRenderParams(paramValues) {
    return paramValues.map((item, i) => {
      var values = Object.values(item);
      var keys = Object.keys(item);
      console.log(keys);
      return (<th key={i}>{keys[0]}</th>);
    });
  }

  render() {

    let component = this;

    //console.log(component.state.result);

    if(component.state.result[0]) {
      var paramValues = Object.values(JSON.parse(component.state.result[0][1][0].node.field_parameters[0].value));
    } else {
      var paramValues = [];
    }

    let renderParams = paramValues.map((item, i) => {
      var values = Object.values(item);
      var keys = Object.keys(item);
      console.log(keys);
      return (<th key={i}>{keys[0]}</th>);
    });


    return(
      <>

      {!this.state.loaded &&
        <div className="text-center"><MDBSpinner key={'treatment-loader'} red /></div>
      }


        {this.state.loaded &&
          <>

            {!this.props.treatment.field_is_completed[0].value && PermissionHelper._hasPermission('restful post Session') === true &&
              <SessionForm stencil={this.props.stencil} searchComponent={this} ref={this.formRef} nid={this.props.nid} lastSession={this.state.result[0]} sessions={this.state.result} />
            }

            <hr />


            {component.state.result.map(function(item, i){
              console.log(item)
              return (
                <div key={i}>
                <h5>{moment(item[0]).format("dddd, DD MMMM YYYY")}</h5>
                <MDBTable responsive hover striped>
                  <thead>
                    <tr>

                      <th className='th-lg'>
                        Behandelt von
                      </th>
                      <th className='th-lg'>
                        Behandelt am Standort
                      </th>
                      {component._onRenderParams(Object.values(JSON.parse(item[1][0].node.field_parameters[0].value)))}
                      <th>Bemerkung</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {component.state.loaded && (
                      item[1].map(function(row, j){
                        //console.log(row)
                        return (
                          <SessionResultRow result={row}  key={'session-result-view-' + row.nid + row.changed} searchComponent={component} treatment={component.props.treatment} />
                        )
                      })
                    )}

                  </tbody>
                </MDBTable>
              </div>
              )
            })}








          </>
        }


      </>
    )
  }
}

export default SessionSearch;
