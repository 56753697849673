import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBInput
} from 'mdbreact';

import SessionEditForm from "./SessionEditForm";

import SessionActions from "./actions/SessionActions";

import SessionParameter from "./SessionParameter";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';





class SessionResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      body: '',
      modal: false,
    }

    this._onDelete = this._onDelete.bind(this);
    this._onSave = this._onSave.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }

  _onChange(e) {
    let inputValue = e.target.value;
    let inputName = e.target.name
    this.setState(prevState => ({
        [inputName]: inputValue
    }))
  }

  _onSave(e) {
    let component = this;
    //console.log(e);

    let values = {
      nid: this.props.result.nid,
      body: this.state.body
    }

    SessionActions._patch(values)
      .then(response => {
        component.props.searchComponent.loadSessionCollection();
      });

  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      SessionActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadSessionCollection();
        });
    }
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    //console.log(this.props.result);


    var paramValues = Object.values(JSON.parse(this.props.result.node.field_parameters[0].value));
    const renderParams = paramValues.map((item, i) => {
      var values = Object.values(item);
      var keys = Object.keys(item);
      return (<td key={i}>{values[0]}</td>);
    });

    return (
      <tr>

            <td>
              {this.props.result.author.field_firstname[0].value} {this.props.result.author.field_lastname[0].value}
            </td>
            <td>
              {this.props.result.location.title[0].value}
            </td>


            {renderParams}

            <td>
              {this.props.result.node.body[0] && (
                this.props.result.node.body[0].value
              )}

            </td>


            <td>
              <div className="action-bar">



                {PermissionHelper._hasPermission('restful delete Session') === true &&
                  <>
                    <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                  </>
                }

                {PermissionHelper._hasPermission('restful patch Session') === true && this.props.treatment &&
                  <>
                    <SessionEditForm key={'session-edit-form-' + this.props.result.nid + this.props.result.changed} searchComponent={this.props.searchComponent} zones={this.props.searchComponent.state.treatmentType.field_zones} treatment={this.props.treatment} session={this.props.result.node} location={this.props.result.location.title[0].value} author={this.props.result.author.field_firstname[0].value + ' ' + this.props.result.author.field_lastname[0].value} />
                  </>
                }

              </div>
            </td>
      </tr>
    );
  }
}

export default SessionResultRow;
