import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withRouter } from "react-router";
import { Login } from './components/modules/login/Login'
import AlertComponent from './components/AlertComponent'
import JwtComponent from './components/JwtComponent'
import { AutoLogout } from './components/AutoLogout'
import { history } from './helpers/history';
import { connect } from 'react-redux';
import userActions from './actions/userActions';
import RoutesWithNavigation from './components/RoutesWithNavigation';
import UpdateNotification from './components/modules/updater/UpdateNotification';
//import NotificationWebsocket from './components/NotificationWebsocket';


import moment from 'moment-timezone';

class App extends React.Component {
  constructor(props) {
    super(props);

    moment.tz.setDefault('Etc/Utc');

    //console.log(props);
    this.props.checkSession(this.props.user);

    this._handleContextMenu = this._handleContextMenu.bind(this);
    this._handleClick = this._handleContextMenu.bind(this);
    this._handleScroll = this._handleContextMenu.bind(this);
  }

  componentDidMount() {
    let component = this;


    this.alertRef = React.createRef();

    document.addEventListener('contextmenu', this._handleContextMenu);

    //console.log(this.props);
    /** set session active **/
    this.sessionCheckInterval = setInterval(function() {
      component.props.checkSession(component.props.user);
    }, 360 * 1000);
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', this._handleContextMenu);
  }


  _handleContextMenu = (event) => {
    //event.preventDefault();
  };


  render() {
    return (
      <>
        <Switch>
          <Route path='/pages/login' exact component={Login} />
          <RoutesWithNavigation />
        </Switch>
        <AlertComponent ref={this.alertRef} />


        <JwtComponent />
        <UpdateNotification alert={this.alertRef} />
        {/*<AutoLogout />*/}
      </>
    );
  }
}


function mapState(state) {
    const { authenticationReducer } = state;
    const { user, locations } = authenticationReducer;
    return { user, locations };
}

const actionCreators = {
  checkSession: userActions.checkSession
}


const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
