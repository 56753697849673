import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBAnimation,
  MDBNavLink,
  MDBIcon,
  MDBCardText,
  MDBCollapseHeader,
  MDBCollapse,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView
} from 'mdbreact';
import { Editor } from '@tinymce/tinymce-react';
import { history } from '../../../helpers/history';

import SessionActions from './actions/SessionActions';
import SessionParameter from "./SessionParameter";
import SessionExtendedForm from "./SessionExtendedForm";
import SessionResultRow from "./SessionResultRow";
import SessionStencil from "./SessionStencil";


import CanvasDraw from "react-canvas-draw";
import mergeImages from 'merge-images';
import TreatmentActions from '../treatment/actions/TreatmentActions';




import moment from 'moment';
import 'moment/locale/de';




class SessionForm extends Component {

  constructor(props) {
    super(props);


    //console.log(props);

    /** states **/
    this.state = {
      collapseID: "collapse2",
      loaded: true,
      modal: false,
      parameters: [],
      parameterValues: [],
      body: '',
      field_treatment: this.props.nid,
      field_location: localStorage.getItem('selectedLocation')
    }



    /** binding methods **/
    this._onSubmit = this._onSubmit.bind(this);
    this._onChange = this._onChange.bind(this);
    this._onChangeParamter = this._onChangeParamter.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onToggleCollapse = this._onToggleCollapse.bind(this);
    this._onPreventToggle = this._onPreventToggle.bind(this);

  }


  componentDidMount() {
  }

  _onChange(value) {
    let inputValue = value.target.value;
    let inputName = value.target.name

    this.setState(prevState => ({
        [inputName]: inputValue
    }))
  }

  _onChangeParamter(value) {

    let inputValue = value.target.value;
    let inputName = value.target.name;

    //console.log(inputValue);
    //console.log(inputName);

    let parameterValues = this.state.parameterValues;
    parameterValues[inputName] = inputValue;

    this.setState({
      parameterValues: parameterValues
    })

    //console.log(this.state);
  }

  _onSubmit(event) {
    var component = this;
    event.preventDefault();
    //console.log(this.state);
    component.setState({
      loaded: false
    })



    SessionActions._post(this.state).then(response => {


      component.props.searchComponent.props.treatmentDetail._loadTreatmentDetails();
      component._onToggleModal();
      component.setState({
        body: ''
      })

      component.props.searchComponent.loadSessionCollection();
    });
  }

  _onToggleModal = () => {
      this.setState({
        modal: !this.state.modal
      });
    }

  _onToggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
  }));

  _onPreventToggle() {
    return false;
  }




  render() {
    var component = this;
    //console.log(this.props.searchComponent);

    //console.log(this.props.lastSession);


    if(this.props.lastSession) {
      var paramValues = Object.values(JSON.parse(this.props.lastSession[1][0].node.field_parameters[0].value));
    } else {
      var paramValues = [];
    }

    const renderParams = paramValues.map((item, i) => {
      var values = Object.values(item);
      var keys = Object.keys(item);
      //console.log(keys);
      return (<th key={i}>{keys[0]}</th>);
    });

    return (
      <>
        <section id="session-form">
            <>
            <MDBBtn color="primary" onClick={this._onToggleModal}><MDBIcon icon="plus mr-2" /> Neue Sitzung starten</MDBBtn>
            <MDBModal isOpen={this.state.modal} toggle={this._onPreventToggle} size="xl">
              <MDBModalHeader toggle={this._onToggleModal}>Neue Sitzung</MDBModalHeader>
              <MDBModalBody>
                <form validate="true" onSubmit={this._onSubmit}>

                  {this.props.lastSession &&
                    <>
                      <MDBCollapseHeader onClick={this._onToggleCollapse("collapse1")}>
                        Letzte Sitzung
                        <i className={ this.state.collapseID==="collapse1" ? "fa fa-angle-up" : "fa fa-angle-down" } />
                      </MDBCollapseHeader>
                      <MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
                        <MDBCardBody>


                              <h5>{moment(this.props.lastSession[0]).format("dddd, DD MMMM YYYY")}</h5>
                              <MDBTable responsive hover striped>
                                <thead>
                                  <tr>
                                    <th className='th-lg'>
                                      Behandelt von
                                    </th>
                                    <th className='th-lg'>
                                      Behandelt am Standort
                                    </th>
                                    {renderParams}
                                    <th>Bemerkung</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    component.props.lastSession[1].map(function(row, j){
                                      //console.log(row)
                                      return (
                                        <SessionResultRow result={row}  key={'session-result-view-' + row.nid + row.changed} searchComponent={component.props.searchComponent} />
                                      )
                                    })
                                  }

                                </tbody>
                              </MDBTable>







                        </MDBCardBody>
                      </MDBCollapse>
                    </>
                  }



                      {this.props.searchComponent.state.treatmentType && this.props.searchComponent.state.treatmentType.tid[0].value != "5" && this.props.searchComponent.state.treatmentType.tid[0].value != "37" &&
                          <>

                          <MDBCollapseHeader onClick={this._onToggleCollapse("collapse2")}>
                            Parameter
                            <i className={ this.state.collapseID==="collapse2" ? "fa fa-angle-up" : "fa fa-angle-down" } />
                          </MDBCollapseHeader>
                          <MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
                            <MDBCardBody>

                          {this.props.searchComponent.state.treatmentType.field_parameters.map(function(item, i){
                              //console.log(item);
                                  let fieldName = i;
                                  let prev = i - 1;
                                  let prevFieldName = prev;
                                  return (
                                    <div className="input-item" key={i}>
                                      <MDBInput
                                        name={fieldName}
                                        label={item.value}
                                        group
                                        type='text'
                                        error='wrong'
                                        success='right'
                                        required
                                        onChange={component._onChangeParamter}
                                        value={component.state.fieldName}
                                        key={i}
                                      />
                                    </div>
                                  );
                            })}

                            <div className="input-item">
                              <MDBInput
                                name="body"
                                label="Bemerkung"
                                group
                                type='text'
                                error='wrong'
                                success='right'
                                required
                                onChange={component._onChange}
                                value={component.state.body}
                              />
                            </div>

                            <MDBBtn color="primary" type="submit"><MDBIcon far icon="save mr-2" /> Sitzung abschliessen</MDBBtn>

                            </MDBCardBody>
                          </MDBCollapse>

                            </>
                      }


                </form>





                {this.props.searchComponent.state.treatmentType && this.props.searchComponent.state.treatmentType.tid[0].value == "5" &&
                  <>
                    <MDBCollapseHeader onClick={this._onToggleCollapse("collapse2")}>
                      Parameter
                      <i className={ this.state.collapseID==="collapse2" ? "fa fa-angle-up" : "fa fa-angle-down" } />
                    </MDBCollapseHeader>
                    <MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
                      <MDBCardBody>
                        <SessionExtendedForm lastSessions={this.props.lastSession} form={this} zones={this.props.searchComponent.state.treatmentType.field_zones} parameters={this.props.searchComponent.state.treatmentType.field_parameters} treatmentId={component.state.field_treatment} />
                      </MDBCardBody>
                    </MDBCollapse>
                  </>
                }

                {this.props.searchComponent.state.treatmentType && this.props.searchComponent.state.treatmentType.tid[0].value == "37" &&
                  <>
                    <MDBCollapseHeader onClick={this._onToggleCollapse("collapse2")}>
                      Parameter
                      <i className={ this.state.collapseID==="collapse2" ? "fa fa-angle-up" : "fa fa-angle-down" } />
                    </MDBCollapseHeader>
                    <MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
                      <MDBCardBody>
                        <SessionExtendedForm lastSessions={this.props.lastSession} form={this} zones={this.props.searchComponent.state.treatmentType.field_zones} parameters={this.props.searchComponent.state.treatmentType.field_parameters} treatmentId={component.state.field_treatment} />
                      </MDBCardBody>
                    </MDBCollapse>
                  </>
                }


                {this.props.stencil &&
                  <>
                    <MDBCollapseHeader onClick={this._onToggleCollapse("collapse3")}>
                      Schablone
                      <i className={ this.state.collapseID==="collapse3" ? "fa fa-angle-up" : "fa fa-angle-down" } />
                    </MDBCollapseHeader>

                    <MDBCollapse id="collapse3" isOpen={this.state.collapseID}>
                      <MDBCardBody>
                          <SessionStencil nid={component.props.nid} stencil={this.props.stencil} />
                      </MDBCardBody>
                    </MDBCollapse>
                  </>
                }



              </MDBModalBody>
            </MDBModal>
            </>
      </section>
      </>
    );
  }
}

export default SessionForm;
