import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse,
  MDBTimePicker,
} from 'mdbreact';
import CheckupActions from './actions/CheckupActions';
import { history } from '../../../helpers/history';

import MDBFileupload from 'mdb-react-fileupload';

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';

import Image from "../image/Image";

class CheckupForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      modal: false,
      nid: false,
      entity: this.props.entity,
      loaded: true,
      field_datetime: new Date(Date.now()),
      field_time: '12:00:00',
      body: '',
      field_treatment: false,
    }

    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onGetPickerValue = this._onGetPickerValue.bind(this);
    this._onGetTimePickerValue = this._onGetTimePickerValue.bind(this);
  }

  componentDidMount() {
    if(this.props.entity) {
      this.setState(prevState => ({
        loaded: true,
        nid: this.props.entity.nid[0].value,

      }))
    } else {
      if(PermissionHelper._hasPermission('ignore locations') === false) {

      }
    }
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  _onSubmit(e) {
    e.preventDefault();
    var component = this;

    if(component.state.hasSubmitted) {
      return;
    }

    component.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))

    let values = {
      field_treatment: this.props.nid,
      field_datetime: this.state.field_datetime,
      field_time: this.state.field_time,
      body: this.state.body,
    }

    if(!this.state.nid) {
      CheckupActions._post(values)
        .then(response => {


          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))

          component._onToggleModal();
          component._onFormReset();

          if(component.props.searchComponent) {
            component.props.searchComponent.loadCheckupCollection();
          }

        });
    } else {
      CheckupActions._patch(values)
        .then(response => {

          component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
          }))

          component._onToggleModal();
          if(component.props.searchComponent) {
            component.props.searchComponent.loadCheckupCollection();
          }

        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }



  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      nid: false,
      field_datetime: new Date(Date.now()),
      body: '',
      field_treatment: false,
    }))
  }

  _onGetPickerValue = (value) => {
    //console.log(value);
    //var date = new Date(value).toJSON();
    this.setState({
      field_datetime: value
    });
  }

  _onGetTimePickerValue(value) {
    //console.log(value);
    this.setState(prevState => ({
      field_time: value,
    }))
  }

  render() {
    let component = this;
    return (
      <>
        {!this.state.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Termin erfassen</MDBBtn>
        }

        {this.state.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal} size="lg">
          <MDBModalHeader toggle={this._onToggleModal}>Terminverwaltung</MDBModalHeader>
          <MDBModalBody>
            <MDBCard className='mt-0'>
                <form validate="true" onSubmit={this._onSubmit}>

                  <MDBRow>
                    <MDBCol>
                      <div className="md-form form-group form-datepicker">
                        <MDBDatePicker id="field_birthday" name="field_birthday" required cancelLabel="Abbrechen" locale={moment.locale('de')} value={this.state.field_birthday} getValue={this._onGetPickerValue} />
                        <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#a1144b" }}>Datum</label>
                      </div>
                    </MDBCol>
                    <MDBCol>
                      <MDBTimePicker id="field_time" name="field_time" label="Uhrzeit" value={this.state.field_time} getValue={this._onGetTimePickerValue} hoursFormat={24} />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol size="12">
                      <MDBInput type="textarea" label="Bemerkung" rows="3" name="body" value={this.state.body} onChange={this._onInputChange} required />
                    </MDBCol>
                  </MDBRow>

                  <MDBBtn color="primary" disabled={this.state.hasSubmitted ? true : false}  type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>

                </form>
            </MDBCard>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}

export default CheckupForm;
