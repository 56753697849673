import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar,
  MDBModalHeader,
  MDBTooltip,
  MDBBadge
} from 'mdbreact';


import Image from "../image/Image";
import NumberFormat from 'react-number-format';
import RecommendationActions from "./actions/RecommendationActions";
import RecommendationForm from "./RecommendationForm";
import RecommendationComment from "../comment/CommentSearch.jsx";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";



class RecommendationResultRow extends React.Component {

  constructor(props) {
    super(props);


    this.recommendationCommentRef = React.createRef();

    this.state = {
      modal: false,
      commentCount: 0,
      recommendationComment: <RecommendationComment ref={this.recommendationCommentRef} showImportant={false} entityId={this.props.result.node.nid[0].value} entityType="node" fieldName="field_recommendation_comment" commenType="recommendation_comment" />,
    };




    this._onDelete = this._onDelete.bind(this);
    this._onUse = this._onUse.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }


  componentDidMount() {

    console.log(this.state.recommendationComment);

  }

  componentDidUpdate() {

  }


  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      RecommendationActions._delete(this.props.result.nid)
        .then(response => {
          //component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadRecommendationCollection();
        });
    }
  }

  _onUse(e) {
    var component = this;

    const r = window.confirm("Bitte bestätigen Sie Ihre Auswahl.");
    if (r === true) {

      var values = {
        nid: this.props.result.nid,
        field_used: 1
      }

      RecommendationActions._patch(values)
        .then(response => {
          //component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadRecommendationCollection();
        });
    }
  }



  render() {

    var showStatusButtons = true;

    if(this.props.result.node.field_used[0].value) {
      showStatusButtons = false;
    }

    return (
      <tr className="recommendation-item" key={'recommendation-' + this.props.result.nid + this.props.result.changed}>

        <td>{(this.props.result.node.field_recommendation_number[0] ? this.props.result.node.field_recommendation_number[0].value : '')}</td>
        <td>{(this.props.result.node.created[0] ? moment(this.props.result.node.created[0].value).format("DD.MM.YYYY HH:mm") : '')}</td>
        <td>
          {this.props.result.node.field_used[0].value &&
            <>
            {(this.props.result.node.changed[0] ? moment(this.props.result.node.changed[0].value).format("DD.MM.YYYY HH:mm") : '')}
            </>
          }
          {!this.props.result.node.field_used[0].value &&
            <>
            noch nicht eingelöst
            </>
          }

        </td>
        <td className="hide">
          {!this.props.result.node.field_used[0].value &&
            <MDBIcon icon="ban" />
          }

          {this.props.result.node.field_used[0].value &&
            <MDBIcon far icon="check-square" />
          }
        </td>
        <td>{(this.props.result.customer.title[0] ? this.props.result.customer.title[0].value : '')}</td>
        {/*<td>{(this.props.result.node.body[0] ? this.props.result.node.body[0].value : '')}</td>*/}
        <td>

          <div className="action-bar">




            {showStatusButtons &&
              <>


              <MDBTooltip
                  domElement
                  tag="span"
                  placement="top"
                >
                  <span>
                <MDBBtn color="green" className="btn-details" onClick={this._onUse} className="mr-2">
                   <MDBIcon far icon="check-square" />
                </MDBBtn>
                </span>
                <span>Rabatt einlösen</span>
              </MDBTooltip>



              </>
            }






            {PermissionHelper._hasPermission('restful delete Recommendation') === true &&
              <>
                <MDBBtn className="mr-2" color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
              </>
            }
            {false && PermissionHelper._hasPermission('edit any recommendation content') === true &&
              <>
                <RecommendationForm key={'recommendation-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} />
              </>
            }
          </div>



        </td>
      </tr>
    );
  }
}

export default RecommendationResultRow;
